import React, { useState } from "react";
import NewRegistration from "../components/NewRegistration";
import DatePicker from "../components/DatePicker";
import RegistrationList from "../components/RegistrationList";
import { getDatePickerStringFromDate } from "../utils/formatter";
import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useQuery } from "react-query";
import api from "../utils/api";
import useActivities from "../hooks/useActivities";
import useSymptoms from "../hooks/useSymptoms";
import { useMemo } from "react";
import NudgingPromptModal from "../components/NudgingPromptModal";

const Registrations = () => {
  const [isNudgningPromptOpen, setIsNudgningPromptOpen] = useState(false)
  const [nudgingText, setnudgingText] = useState("")

  const { user } = useAuth();

  const { isFetched: isFetchedActivities } = useActivities()
  const { isFetched: isFetchedSymptoms } = useSymptoms()

  // Refetch every hour or when the user reconnects or focuses the window (to trigger nightsleep prompt)
  const { data, isFetched } = useQuery("registrations", () =>
    api.get(`clients/${user.uid}/registrations/overview`).then((res) => res.data), { refetchIntervalInBackground: 1000 * 60 * 60, refetchOnReconnect: true, refetchOnWindowFocus: true, enabled: isFetchedActivities && isFetchedSymptoms }
  );

  const [datefilter, setDatefilter] = useState(
    getDatePickerStringFromDate(new Date())
  );

  const [editRegistration, setEditRegistration] = useState(null)

  const changeDateDayByChange = (date, change) => {
    const currentDate = new Date(date)
    currentDate.setDate(currentDate.getDate() + change)
    setDatefilter(getDatePickerStringFromDate(currentDate))
  }

  const changeDateToToday = () => {
    setDatefilter(getDatePickerStringFromDate(new Date()))
  }

  const registrationTodayExists = useMemo(() => {
    return data?.filter(x => x.created.substring(0, 10) === new Date().toISOString().substring(0, 10) && x.category !== 0).length > 0
  }, [data])

  useEffect(() => {
    if (user.promptNightSleep && isFetched && !registrationTodayExists) {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const registrationsDayBefore = data?.filter(x => x.created.substring(0, 10) === yesterday.toISOString().substring(0, 10)).sort((a, b) => b.endTime - a.endTime)
      let date = new Date().toISOString().substring(0, 10);
      let startTime = "00.00"
      if (registrationsDayBefore[0] !== undefined && registrationsDayBefore[0].endTime >= "20.00") {
        date = yesterday.toISOString().substring(0, 10)
        startTime = registrationsDayBefore[0].endTime
      }

      const model = {
        date,
        activity: "Nattesøvn",
        startTime,
        endTime: undefined,
        symptoms: [],
        category: 0
      }
      setEditRegistration(model)
    }
  }, [user, data, registrationTodayExists, isFetched])

  const isNightSleepPrompt = user.promptNightSleep && editRegistration !== null && !registrationTodayExists

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {
          isNightSleepPrompt && <h2 className="text-xl font-semibold text-center pb-4">Hvordan har din nattesøvn været?</h2>
        }
        <div className="flex justify-center">
          <NewRegistration editRegistration={editRegistration} setEditRegistration={setEditRegistration} setnudgingText={setnudgingText} setIsNudgningPromptOpen={setIsNudgningPromptOpen} isNightSleepPrompt={isNightSleepPrompt} />
        </div>
        {
          !editRegistration &&
          <>
            <div className="flex flex-row gap-2 justify-center items-center my-8">
              <button className="p-2 border-[1px] border-black/60 rounded-md" onClick={() => { changeDateDayByChange(datefilter, -1) }}><ChevronLeftIcon className="h-5 w-5" /></button>
              <DatePicker
                id="datefilter"
                name="datefilter"
                value={datefilter}
                onChange={(e) => setDatefilter(e.target.value)}
                textSize="xs"
              />
              <button className="p-2 border-[1px] border-black/60 rounded-md" onClick={() => { changeDateDayByChange(datefilter, 1) }}><ChevronRightIcon className="h-5 w-5" /></button>
              <button className="p-2 border-[1px] border-black/60 rounded-md text-sm" onClick={() => { changeDateToToday() }}>I dag</button>
            </div>
            <RegistrationList datefilter={datefilter} setEditRegistration={setEditRegistration} />
          </>
        }
      </motion.div>
      <NudgingPromptModal isOpen={isNudgningPromptOpen} setIsOpen={setIsNudgningPromptOpen} nudgingText={nudgingText} />
    </>
  );
};

export default Registrations;
