import Layout from "../components/Layout";
import { AppProvider } from "../contexts/AppContext"
import { Outlet } from "react-router-dom";

export default function HomePage() {
  return (
    <AppProvider>
      <Layout>
        <Outlet />
      </Layout>
    </AppProvider>
  );
}
