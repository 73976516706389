import { useMemo, useState } from "react";
import Button from "./Button";
import Category from "./Category";
import DatePicker from "./DatePicker";
import DatePicker2 from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PlusIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { XCircleIcon } from "@heroicons/react/outline";
import CreatableSelect from "react-select/creatable";
import Select from 'react-select'
import { getDatePickerStringFromDate } from "../utils/formatter";
import api from "../utils/api";
import Spinner from "./Spinner";
import { useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import useActivities from "../hooks/useActivities";
import useSymptoms from "../hooks/useSymptoms";
import { useAuth } from "../contexts/AuthContext";
import useClientWorkspaceConsent from "../hooks/useClientWorkspaceConsent";
import WorkplaceConsentRequiredDialog from "./WorkplaceConsentRequiredDialog";

const customStyles = {
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "#3383b1",
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "white",
        ":hover": {
            backgroundColor: data.color,
            color: "white",
        },
    }),
};

// Get saved date data from session storage and check if it is still valid
const useSavedDateData = () => {
    const savedDateData = sessionStorage.getItem("savedDateData")
    if (savedDateData) {
        const parsedSavedDateData = JSON.parse(savedDateData)
        if (parsedSavedDateData.expires >= new Date().getTime()) {
            return new Date(parsedSavedDateData.date)
        }
    }
    return new Date()
}

export default function NewRegistration({ editRegistration, setEditRegistration, setnudgingText, setIsNudgningPromptOpen, isNightSleepPrompt }) {
    const initialDate = getDatePickerStringFromDate(useSavedDateData());
    const { user } = useAuth();

    const [pageState, setPageState] = useState(null);
    const [date, setDate] = useState(initialDate);
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [activity, setActivity] = useState(null);
    const [symptoms, setSymptoms] = useState([]);
    const [category, setCatogory] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [showConsentRequiredDialog, setShowConsentRequiredDialog] = useState(false);

    const customSymptoms = symptoms.filter(x => typeof x.value === "string")

    const queryClient = useQueryClient();

    const { data: activitiesFromApiData, isFetched: isFetchedActivities } = useActivities()
    const { data: symptomsFromApiData, isFetched: isFetchedSymptoms } = useSymptoms()
    const { data: clientWorkspaceConsent } = useClientWorkspaceConsent();

    const symptomOptions = useMemo(() => {
        if (symptomsFromApiData) {
            return symptomsFromApiData.map((s) => ({
                value: s.id,
                label: s.name,
                enabled: s.enabled,
                category: s.category
            }));
        }
        return []
    }, [symptomsFromApiData])

    const { data } = useQuery("registrations", () =>
        api.get(`clients/${user.uid}/registrations/overview`).then((res) => res.data), { refetchOnReconnect: false, refetchOnWindowFocus: false, enabled: isFetchedActivities && isFetchedSymptoms }
    );

    const {
        data: mostUsedActivitesData,
        refetch: mostUsedActivitesRefetch
    } = useQuery("activities/mostUsedActivities", () =>
        api.get(`clients/${user.uid}/activities/mostUsedActivities`)
            .then((response) => response.data), { refetchOnReconnect: false, refetchOnWindowFocus: false }
    );

    const { data: mostUsedSymptomsData, refetch: mostUsedSymptomsRefetch } = useQuery("symptoms/mostUsedSymptoms", () =>
        api.get(`clients/${user.uid}/symptoms/mostUsedSymptoms`)
            .then((response) => response.data), { refetchOnReconnect: false, refetchOnWindowFocus: false }
    );

    const registrationTemplatesTodayExists = useMemo(() => {
        return data?.filter(x => x.created.substring(0, 10) === new Date().toISOString().substring(0, 10) && x.category === 0).length > 0
    }, [data])

    // Set fromTime to the latest end time of today's registrations
    useEffect(() => {
        if (data && data.length > 0) { // If there are registrations
            if (registrationTemplatesTodayExists) return; // If there are templates active

            const today = new Date().toISOString().substring(0, 10)
            const registrationsToday = data?.filter(x => x.date.substring(0, 10) === today);
            if (registrationsToday.length < 1) return;
            const max = registrationsToday.reduce((max, p) => p.endTime > max ? p.endTime : max, registrationsToday[0].endTime);
            const newFromTime = new Date().setHours(max.split(".")[0], max.split(".")[1], 0, 0);
            setFromTime(new Date(newFromTime));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (editRegistration) {
            if (editRegistration.date)
                setDate(editRegistration.date)
            if (editRegistration.date && editRegistration.startTime)
                setFromTime(new Date(editRegistration.date + 'T' + editRegistration.startTime.replace('.', ':')))
            if (editRegistration.date && editRegistration.endTime)
                setToTime(new Date(editRegistration.date + 'T' + editRegistration.endTime.replace('.', ':')))
            if (typeof editRegistration.activity === "number") {
                const activity = activitiesFromApiData.find(x => x.value === editRegistration.activity)
                setActivity(activity)
            } else if (editRegistration.activity === "Nattesøvn") {
                setActivity({
                    "label": "Nattesøvn",
                    "value": "Nattesøvn",
                    "__isNew__": true
                })
            }
            if (editRegistration.symptoms) {
                const symptoms = editRegistration.symptoms.map(x => symptomOptions.find(y => x === y.value))
                setSymptoms(symptoms)
            }
            if (editRegistration.category)
                setCatogory(editRegistration.category)

            setPageState('edit')
        }
    }, [activitiesFromApiData, editRegistration, symptomOptions])


    const handleCustomSymptomInputChange = (inputValue) => {
        setSymptoms([...symptoms, { label: inputValue, value: inputValue }]);

    };

    function validateInput() {
        let errorMessages = {};
        if (!fromTime) {
            errorMessages = {
                ...errorMessages,
                fromTime: "Forkert format. Brug F. eks. 13:00-14:00",
            };
        }

        if (!toTime) {
            errorMessages = {
                ...errorMessages,
                toTime: "Forkert format. Brug F. eks. 13:00-14:00",
            };
        }

        if (!category) {
            errorMessages = {
                ...errorMessages,
                category: "Vælg farve.",
            };
        }

        if (!activity) {
            errorMessages = {
                ...errorMessages,
                activity: "Mangler at vælge aktivitet",
            };
        }
        if (symptoms.length === 0) {
            errorMessages = {
                ...errorMessages,
                symptoms: "Mangler at vælge udsagn",
            };
        }
        setErrors(errorMessages);
        return Object.keys(errorMessages).length === 0;
    }

    function submitRegistration() {
        setSubmitting(true);
        setErrors({});

        if (!validateInput() && !validateInput()) {
            setSubmitting(false);
            return;
        }

        const startTime = fromTime.toLocaleTimeString("da-DK").slice(0, -3);
        const endTime = toTime.toLocaleTimeString("da-DK").slice(0, -3);

        const model = {
            date: new Date(date),
            activityId: typeof activity.value === "string" ? -1 : activity.value,
            symptomIds: symptoms.filter(x => typeof x.value !== "string").map((s) => s.value),
            startTime,
            endTime,
            category,
            customActivity: typeof activity.value === "string" ? activity.value : activity.label,
            customSymptoms: customSymptoms.map(x => x.label)
        };

        if (pageState === 'new' || !editRegistration.id)
            api
                .post(`clients/${user.uid}/registrations`, model)
                .then(() => {
                    setSubmitting(false);

                    const promise1 = mostUsedActivitesRefetch()
                    const promise2 = mostUsedSymptomsRefetch();
                    Promise.all([promise1, promise2]).then(() => {
                        queryClient.invalidateQueries("registrations")
                    });
                    if (typeof activity.value === "string") queryClient.invalidateQueries("activities/GetClientActivities")
                    if (customSymptoms.length > 0) queryClient.invalidateQueries("symptoms/GetClientSymptoms")
                    setPageState("saved");
                    clearInput();

                    if (user.promptNudging) {
                        const today = new Date().toISOString().substring(0, 10)
                        const registrationsTodaySorted = data.filter(x => x.date.substring(0, 10) === today).sort((a, b) => b.id - a.id)
                        if (registrationsTodaySorted.length > 0 || model.category === 3) {
                            const lastRegistration = registrationsTodaySorted[0];
                            // If red registration or yellow where last was yellow or red
                            if (model.category === 3 || (model.category === 2 && lastRegistration.category >= 2)) {
                                const isBrainPause = activitiesFromApiData.find(x => x.value === model?.activityId)?.brainPause
                                if (isBrainPause) {
                                    setIsNudgningPromptOpen(true)
                                    setnudgingText("Det er tid til en hjernepause mere eller en grøn aktivitet")
                                } else {
                                    setIsNudgningPromptOpen(true)
                                    setnudgingText("Det er tid til en hjernepause")
                                }
                            }
                        }
                    }
                })
                .catch((error) => {
                    const errors = error.response.data.errors
                    if (errors.WorkplaceConsentNotAccepted) {
                        setShowConsentRequiredDialog(true)
                        setSubmitting(false);
                    }

                });
        else if (pageState === 'edit' && editRegistration !== null)
            api
                .patch(`clients/${user.uid}/registrations/${editRegistration.id}`, model)
                .then(() => {
                    setSubmitting(false);
                    const promise1 = mostUsedActivitesRefetch()
                    const promise2 = mostUsedSymptomsRefetch();
                    Promise.all([promise1, promise2]).then(() => {
                        queryClient.invalidateQueries("registrations")
                    });
                    if (typeof activity.value === "string") queryClient.invalidateQueries("activities/GetClientActivities")
                    if (customSymptoms.length > 0) queryClient.invalidateQueries("symptoms/GetClientSymptoms")
                    setPageState("savedEdit");
                    clearInput();
                })
                .catch((error) => {
                    const errors = error.response.data.errors
                    if (errors.WorkplaceConsentNotAccepted) {
                        setShowConsentRequiredDialog(true)
                        setSubmitting(false);
                    }

                });
    }

    function deleteRegistration() {
        api
            .delete(`clients/${user.uid}/registrations/${editRegistration.id}`)
            .then(() => {
                setSubmitting(false);
                queryClient
                    .invalidateQueries("registrations");
                setPageState("deleted");
                clearInput();
            })
            .catch((error) => {
                const errors = error.response.data.errors
                if (errors.WorkplaceConsentNotAccepted) {
                    setShowConsentRequiredDialog(true)
                    setSubmitting(false);
                }

            });
    }

    function clearInput() {
        setDate(initialDate);
        setActivity(null);
        setSymptoms([]);
        setCatogory(null);
        setFromTime("");
        setToTime("");
    }

    function stateText(state) {
        switch (state) {
            case "saved":
                return "gemt"
            case "savedEdit":
                return "rettet"
            case "deleted":
                return "slettet"
            default:
                break;
        }
    }

    function symtomsContainsId(id) {
        return symptoms.findIndex(y => y.value === id) !== -1
    }

    function canModifyData() {
        const canModifyData = clientWorkspaceConsent.canModifyData
        if (canModifyData) return true

        setShowConsentRequiredDialog(true)

        return false;
    }

    if (pageState === "saved" || pageState === "savedEdit" || pageState === "deleted") {
        return (
            <Button
                color="green"
                rounded="xl"
                onClick={() => {
                    setEditRegistration(null)
                    setPageState(null);
                }}
            >
                <div className="py-4">
                    <div className="flex justify-center">
                        <CheckCircleIcon className="h-12 w-12" />
                    </div>
                    <div className="font-barlow font-semibold text-[25px] py-2">
                        Din aktivitet er {stateText(pageState)}
                    </div>
                    <div className="font-barlow text-[16px] py-2">
                        Du kan altid se dine aktiviteter i din aktivitetskalender
                    </div>
                </div>
            </Button>
        );
    }

    if (pageState === "new" || pageState === "edit") {
        return (
            <>
                <div className="flex justify-center bg-green rounded-xl p-4 font-barlow">
                    <div className="flex flex-col">
                        <div className="flex justify-end">
                            <XCircleIcon
                                className="h-6 w-6 cursor-pointer"
                                onClick={() => {
                                    clearInput()
                                    setEditRegistration(null)
                                    setPageState(null);
                                }}
                            />
                        </div>
                        <div className="my-3" />

                        {!isNightSleepPrompt &&
                            <label className="flex flex-col">
                                <span className="text-[16px] font-semibold">
                                    Hvornår udførte du aktiviteten?
                                </span>
                                <DatePicker
                                    id="date"
                                    name="date"
                                    value={date}
                                    onChange={(e) => {
                                        const savedDateData = {
                                            date: e.target.value,
                                            expires: new Date(new Date().setHours(24, 0, 0, 0)).getTime() // Expires at midnight
                                        }
                                        sessionStorage.setItem("savedDateData", JSON.stringify(savedDateData));
                                        setDate(e.target.value)
                                    }}
                                />
                                {errors.date ? (
                                    <p className="h-[30px] text-red text-sm">{errors.date}</p>
                                ) : (
                                    <div className="h-[30px]" />
                                )}
                            </label>
                        }
                        {!isNightSleepPrompt &&
                            <span className="text-[16px] font-semibold">
                                I hvilket tidsrum udførte du aktiviteten?
                            </span>
                        }
                        <div className="flex space-x-4">
                            {!isNightSleepPrompt &&
                                <label htmlFor="fromTime" className="flex flex-1 flex-col">
                                    <span className="text-[16px] font-semibold">Fra</span>
                                    <DatePicker2
                                        className="focus:ring-lightgreen-500 focus:border-lightgreen-500 shadow-sm sm:text-sm border-gray-300 rounded-md w-full"
                                        selected={fromTime}
                                        onChange={(date) => setFromTime(date)}
                                        onFocus={e => e.target.blur()}
                                        onChangeRaw={(e) => {
                                            e.preventDefault();
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Vælg tid"
                                        timeFormat="HH:mm"
                                        dateFormat="HH:mm"
                                    />
                                    {errors.fromTime ? (
                                        <p className="h-[30px] text-red text-sm">{errors.fromTime}</p>
                                    ) : (
                                        <div className="h-[30px]" />
                                    )}
                                </label>
                            }

                            <label htmlFor="toTime" className='flex flex-1 flex-col'>
                                <span className="text-[16px] font-semibold">
                                    {isNightSleepPrompt ? "Hvornår sluttede din nattesøvn?" : "Til"}
                                </span>
                                <DatePicker2
                                    className="focus:ring-lightgreen-500 focus:border-lightgreen-500 shadow-sm sm:text-sm border-gray-300 rounded-md w-full"
                                    selected={toTime}
                                    onChange={(date) => setToTime(date)}
                                    showTimeSelect
                                    onFocus={e => e.target.blur()}
                                    onChangeRaw={(e) => {
                                        e.preventDefault();
                                    }}
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Vælg tid"
                                    timeFormat="HH:mm"
                                    dateFormat="HH:mm"
                                />
                                {errors.toTime ? (
                                    <p className="h-[30px] text-red text-sm">{errors.toTime}</p>
                                ) : (
                                    <div className="h-[30px]" />
                                )}
                            </label>
                        </div>

                        {!isNightSleepPrompt &&
                            <label className="flex flex-col">
                                <span className="text-[16px] font-semibold">
                                    Hvilken aktivitet udførte du?
                                </span>
                                <CreatableSelect
                                    isClearable
                                    createOptionPosition="first"
                                    formatCreateLabel={(input) => "Klik for at oprette:  " + input}
                                    onChange={setActivity}
                                    placeholder="Vælg..."
                                    value={activity}
                                    options={
                                        activitiesFromApiData?.filter((a) => a.enabled) ?? []
                                    }
                                    noOptionsMessage={() => 'Ingen tilføjet endnu'}
                                />
                                <div className="flex flex-row flex-wrap gap-2 max-w-[400px] py-2">
                                    {mostUsedActivitesData && mostUsedActivitesData.map(x => <div key={x.id}
                                        className="px-2 py-1 text-sm font-medium rounded-md bg-lightgreen truncate cursor-pointer hover:bg-darkgreen"
                                        onClick={() => {
                                            const activity = activitiesFromApiData.find(y => y.value === x.id)
                                            setActivity(activity)
                                        }}>{x.name}</div>)}
                                </div>

                                {errors.activity ? (
                                    <p className="h-[30px] text-red text-sm">{errors.activity}</p>
                                ) : (
                                    <div className="h-[30px]" />
                                )}
                            </label>
                        }

                        <label className="flex flex-col">
                            <span className="text-[16px] font-semibold">
                                Hvilke udsagn beskriver dit energiniveau efter endt aktivitet?
                            </span>
                            <Select
                                isClearable
                                // createOptionPosition="first"
                                defaultValue={symptoms}
                                onChange={setSymptoms}
                                value={symptoms}
                                onCreateOption={handleCustomSymptomInputChange}
                                options={
                                    symptomOptions?.filter((a) => a.enabled) ?? []
                                }
                                noOptionsMessage={() => 'Ingen tilføjet endnu'}
                                isMulti
                                placeholder="Udsagn"
                                styles={customStyles}
                            // formatCreateLabel={(userInput) => "Klik for at oprette:  " + userInput}
                            />
                            <div className="flex flex-row flex-wrap gap-2 max-w-[400px] py-2">
                                {mostUsedSymptomsData && mostUsedSymptomsData.map(x => <div key={x.id}
                                    className="px-2 py-1 text-sm font-medium rounded-md bg-lightgreen truncate cursor-pointer hover:bg-darkgreen"
                                    onClick={() => {
                                        const symptomToAdd = {
                                            enabled: x.enabled,
                                            label: x.name,
                                            value: x.id
                                        }
                                        if (!symtomsContainsId(x.id))
                                            setSymptoms([...symptoms, symptomToAdd])
                                    }}>{x.name}</div>)}
                            </div>
                            {errors.symptoms ? (
                                <p className="h-[30px] text-red text-sm">{errors.symptoms}</p>
                            ) : (
                                <div className="h-[30px]" />
                            )}
                        </label>

                        <label htmlFor="category" className="text-[16px] font-semibold">
                            {isNightSleepPrompt ? "Hvordan har din nattesøvn været?" : "Hvilken farve stemmer overens med dine udsagn?"}
                        </label>
                        <div className="flex flex-row justify-evenly space-x-4 py-5 h-16">
                            <div className="w-6">
                                <Category
                                    color="green"
                                    border={category === 1}
                                    onClick={() => setCatogory(1)}
                                    clickable
                                    roundedFull
                                />
                            </div>
                            <div className="w-6">
                                <Category
                                    color="yellow"
                                    border={category === 2}
                                    onClick={() => setCatogory(2)}
                                    clickable
                                    roundedFull
                                />
                            </div>
                            <div className="w-6">
                                <Category
                                    color="red"
                                    border={category === 3}
                                    onClick={() => setCatogory(3)}
                                    clickable
                                    roundedFull
                                />
                            </div>
                        </div>
                        {errors.category ? (
                            <p className="h-[30px] text-red text-sm">{errors.category}</p>
                        ) : (
                            <div className="h-[30px]" />
                        )}
                        <div className="flex flex-row gap-2">
                            {pageState === "edit" && editRegistration.id &&
                                <Button
                                    color="white"
                                    rounded="2xl"
                                    onClick={() => {
                                        if (!canModifyData()) return;
                                        deleteRegistration()
                                    }}
                                    disabled={submitting}
                                    fullWidth
                                >
                                    {submitting ? (
                                        <div className="inline-flex items-center p-3">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <div className="p-3">Slet</div>
                                    )}
                                </Button>}
                            <Button
                                color="white"
                                rounded="2xl"
                                onClick={() => {
                                    if (!canModifyData()) return;
                                    submitRegistration()
                                }}
                                disabled={submitting}
                                fullWidth
                            >
                                {submitting ? (
                                    <div className="inline-flex items-center p-3">
                                        <Spinner />
                                    </div>
                                ) : (
                                    pageState === "new" || !editRegistration.id || editRegistration.category === 0 ? // If new, nightsleep prompt or template
                                        <div className="p-3">Gem</div> : <div className="p-3">Ret</div>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
                <WorkplaceConsentRequiredDialog isOpen={showConsentRequiredDialog} setIsOpen={setShowConsentRequiredDialog} />
            </>
        );
    }

    return (
        <>
            <Button
                color="darkgreen"
                rounded="full"
                onClick={() => {
                    if (!canModifyData()) return;
                    setPageState("new");
                }}
            >
                <div className="pt-3 pb-3">
                    <div
                        className="flex flex-row justify-center gap-2 font-barlow font-semibold text-[25px] italic text-white">
                        <PlusIcon className="h-6 w-6" />
                        Opret ny aktivitet
                    </div>
                </div>
            </Button>
            <WorkplaceConsentRequiredDialog isOpen={showConsentRequiredDialog} setIsOpen={setShowConsentRequiredDialog} />
        </>
    );
}
