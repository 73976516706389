
export default function DatePicker({
  id,
  name,
  value,
  onChange,
  placeholder = "",
}) {

  return (
    <input
      type="date"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="focus:ring-lightgreen-500 focus:border-lightgreen-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
    />
  );
}
