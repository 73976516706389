import { useForm } from 'react-hook-form';
import Button from '../components/Button';
import useActivities from '../hooks/useActivities';
import useReflectionSheet from '../hooks/useReflectionSheet';
import api from '../utils/api';
import { useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useReactToPrint } from 'react-to-print';

const defaultValues = {
    brainPauseText: "",
    greenSymptomText: "",
    yellowSymptomText: "",
    redSymptomText: "",
    allowClientEdit: false,
};

export default function ReflectionSheetPage() {
    const { user } = useAuth();
    const { data: activities } = useActivities();
    const { data, isLoading, isError } = useReflectionSheet();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const { reset, register, handleSubmit, watch, formState: { isDirty } } = useForm({
        mode: "onChange",
        defaultValues,
    });

    useEffect(() => {
        if (data) {
            reset(data);
        }
    }, [data, reset])

    const onSubmit = data => {
        api.put(`/clients/${user.uid}/reflectionSheet`, data)
            .then(() => {
                reset(data)
            })
    }

    const brainPause = activities?.filter(x => x.brainPause) ?? [];
    const greenCategory = activities?.filter(x => x.category === 1 && !x.brainPause) ?? [];
    const yellowCategory = activities?.filter(x => x.category === 2 && !x.brainPause) ?? [];
    const redCategory = activities?.filter(x => x.category === 3 && !x.brainPause) ?? [];
    const allowEdit = data?.allowClientEdit ?? false;

    const calcRows = (text) => {
        const minRows = 5;
        const maxRows = 50;
        const rows = text.split('\n').length;
        if (rows < minRows) return minRows;
        if (rows > maxRows) return maxRows;
        return rows
    };

    if (isError) return <div>Der skete en fejl.</div>;
    if (isLoading) return <div>Henter..</div>;

    return (
        <form ref={componentRef} className="m-auto flex w-full flex-col p-6 print:p-12 md:w-[768px] relative" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row justify-between">
                <h1 className="mb-8 text-lg font-semibold uppercase text-gray-800">Refleksionsark</h1>
                {allowEdit &&
                    <div className='flex flex-row gap-2 print:hidden'>
                        <Button onClick={handlePrint}>Eksportér</Button>
                        <Button type='submit' disabled={!isDirty}>Gem</Button>
                    </div>
                }
            </div>
            <div className="columns-1 print:gap-0 relative">
                <div className='block break-before-auto break-inside-avoid break-after-auto'>
                    <h4 className="font-semibold">HVAD ER EN HJERNEPAUSE FOR DIG?</h4>
                    <div className="overflow-hidden rounded border border-[#e5e7eb]">
                        <textarea
                            rows={calcRows(watch("brainPauseText"))}
                            readOnly={!allowEdit}
                            className="w-full resize-none rounded-lg border-0 focus:ring-0 text-[13px] px-2"
                            style={{ lineHeight: "1.4" }}
                            {...register("brainPauseText")}
                        />
                        <ul className="w-full list-inside list-disc px-4 py-2 text-[13px]" style={{ lineHeight: "1.4" }}>
                            {brainPause.map(x => (
                                <li key={x.value}>{x.label}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='block break-before-auto break-inside-avoid break-after-auto pt-6'>
                    <h4 className="font-semibold">HVILKE AKTIVITETER FÅR DIG I GRØNT FELT?</h4>
                    <div className="flex flex-row gap-4">
                        <div className="w-full overflow-hidden rounded border border-[#e5e7eb]">
                            <textarea
                                rows={calcRows(watch("greenSymptomText"))}
                                readOnly={!allowEdit}
                                className="w-full resize-none rounded-lg border-0 focus:ring-0 text-[13px] px-2"
                                style={{ lineHeight: "1.4" }}
                                {...register("greenSymptomText")}
                            />
                            <ul className="list-inside list-disc px-4 py-2 text-[13px]" style={{ lineHeight: "1.4" }}>
                                {greenCategory.map(x => (
                                    <li key={x.value}>{x.label}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-[40px] rounded bg-greencategory" />
                    </div>
                </div>
                <div className='block break-before-auto break-inside-avoid break-after-auto pt-6'>
                    <h4 className="font-semibold">HVILKE AKTIVITETER FÅR DIG I GULT FELT?</h4>
                    <div className="flex flex-row gap-4">
                        <div className="w-full overflow-hidden rounded border border-[#e5e7eb]">
                            <textarea
                                rows={calcRows(watch("yellowSymptomText"))}
                                readOnly={!allowEdit}
                                className="w-full resize-none rounded-lg border-0 focus:ring-0 text-[13px] px-2"
                                style={{ lineHeight: "1.4" }}
                                {...register("yellowSymptomText")}
                            />
                            <ul className="list-inside list-disc px-4 py-2 text-[13px]" style={{ lineHeight: "1.4" }}>
                                {yellowCategory.map(x => (
                                    <li key={x.value}>{x.label}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-[40px] rounded bg-yellowcategory" />
                    </div>
                </div>
                <div className='block break-before-auto break-inside-avoid break-after-auto pt-6'>
                    <h4 className="font-semibold">HVILKE AKTIVITETER FÅR DIG I RØDT FELT?</h4>
                    <div className="flex flex-row gap-4">
                        <div className="w-full overflow-hidden rounded border border-[#e5e7eb]">
                            <textarea
                                rows={calcRows(watch("redSymptomText"))}
                                readOnly={!allowEdit}
                                className="w-full resize-none rounded-lg border-0 focus:ring-0 text-[13px] px-2"
                                style={{ lineHeight: "1.4" }}
                                {...register("redSymptomText")}
                            />
                            <ul className="list-inside list-disc px-4 py-2 text-[13px]" style={{ lineHeight: "1.4" }}>
                                {redCategory.map(x => (
                                    <li key={x.value}>{x.label}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-[40px] rounded bg-redcategory" />
                    </div>
                </div>
            </div>
        </form>
    );
}
