import { useQuery } from "react-query";
import api from "../utils/api";
import { useAuth } from "../contexts/AuthContext";

const getActivities = async (clientUid) =>
  api.get(`clients/${clientUid}/activities`).then((response) =>
    response.data.map((a) => ({
      value: a.id,
      label: `${a.name} ${a.description.length > 0 ? ` (${a.description})` : ""
        }`,
      enabled: a.enabled,
      category: a.category,
      brainPause: a.brainPause ?? false
    }))
  )

export default function useActivities() {
  const { user } = useAuth();

  return useQuery("activities", () => getActivities(user.uid), { refetchOnReconnect: false, refetchOnWindowFocus: false });
}
