


export default function Textarea(
    {
        title,
        formValues,
        onChangeHandler
    }
) {
    return (
        <div>
            <label htmlFor="notice" className="block text-sm font-medium text-gray-700">
                {title}
            </label>
            <div className="mt-1">
                <textarea
                    rows={4}
                    name="notice"
                    id="notice"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm resize-none"
                    value={formValues.note}
                    onChange={(e) => onChangeHandler("notice", e.target.value)}
                />
            </div>
        </div>
    )
}