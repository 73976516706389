import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProtectRoute } from './components/ProtectRoute';
import { AuthProvider } from './contexts/AuthContext';
import './index.css';
import GoalsPage from './pages/Goals';
import RegistrationsPage from './pages/Registrations';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import ResetPasswordPage from './pages/ResetPassword';
import Mfi20Page from './pages/Mfi20';
import MfsPage from './pages/Mfs';
import ReflectionSheetPage from './pages/ReflectionSheet';
import ColorScalePage from './pages/ColorScale';
import WorkplaceConsentPage from './pages/WorkplaceConsentPage';
import WeekOverviewPage from './pages/WeekOverviewPage';

const queryClient = new QueryClient();

ReactDOM.render(
   <React.StrictMode>
      <QueryClientProvider client={queryClient}>
         <BrowserRouter>
            <AuthProvider>
               <ProtectRoute>
                  <Routes>
                     <Route path="/" element={<HomePage />}>
                        <Route path="/" element={<RegistrationsPage />} />
                        <Route path="/goals" element={<GoalsPage />} />
                        <Route path="/reflectionsheet" element={<ReflectionSheetPage />} />
                        <Route path="/colorscale" element={<ColorScalePage />} />
                        <Route path="/consent" element={<WorkplaceConsentPage />} />
                        <Route path="/weekoverview" element={<WeekOverviewPage />} />
                     </Route>
                     <Route path="/mfi" element={<Mfi20Page />} />
                     <Route path="/mfs" element={<MfsPage />} />
                     <Route path="/reset/:requestId" element={<ResetPasswordPage />} />
                     <Route path="/login" element={<LoginPage />} />
                  </Routes>
               </ProtectRoute>
            </AuthProvider>
         </BrowserRouter>
      </QueryClientProvider>
   </React.StrictMode>,
   document.getElementById('root')
);
