import { PieChart, Pie, Cell } from 'recharts';
import { forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import { getDateFromWeek } from './WeekOverviewHelpers';

const colors = {
  green: '#619b72',
  yellow: '#e9aa4f',
  red: '#700c11',
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const displayWeekWithoutLeading0 = (week) =>
  week.split('-')[1].substring(1).charAt(0) === '0'
    ? week.split('-')[1].substring(2)
    : week.split('-')[1].substring(1);

const WeekOverviewDiagrams = forwardRef(({ data, week }, ref) => {
  return (
    <div ref={ref}>
      <div className="hidden flex-col gap-[8px] py-[24px] print:flex">
        <h1 className="-mb-[12px] text-center text-2xl font-bold">
          Ugeoversigt
        </h1>
        <h2 className="text-center text-lg">Uge {displayWeekWithoutLeading0(week)}</h2>
      </div>
      <div className="m-auto flex flex-col p-[12px]">
        <div className="mb-[8px] print:flex print:w-full print:flex-col print:items-center">
          <h2>Ugeoversigt</h2>
          <BarChartComponent data={data} week={week} />
        </div>
        <div className="print:flex print:w-full print:flex-col print:items-center">
          <h2>Procentvis fordeling af farver</h2>
          <div className="flex w-full justify-center sm:justify-start print:sm:justify-center">
            <PieChartComponent data={data} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default WeekOverviewDiagrams;

// ----- BarChartComponent -----

const template = Array.from({ length: 24 * 4 }, () => 0); // 24 hours * 4 quarters

const minutesBetween = (start, end) => {
  const startTime = start.split('.');
  const endTime = end.split('.');
  const startMinutes = Number(startTime[0]) * 60 + Number(startTime[1]);
  const endMinutes = Number(endTime[0]) * 60 + Number(endTime[1]);

  if (endMinutes < startMinutes) {
    return 24 * 60 - startMinutes + endMinutes;
  }

  return endMinutes - startMinutes;
};

const dateToDateOnlyString = (date) => date.toISOString().substring(0, 10);

const getWeekDatesOnly = (week) => {
  const date = getDateFromWeek(week);
  const weekDates = [];
  for (let index = 0; index < 7; index += 1) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + index);
    weekDates.push(dateToDateOnlyString(newDate));
  }
  return weekDates;
};

function BarChartComponent({ data, week }) {
  const weekDates = getWeekDatesOnly(week);

  const weekData = useMemo(() => {
    const internalData = [
      {
        name: 'Mandag',
        date: weekDates[0],
        values: template.slice(),
      },
      {
        name: 'Tirsdag',
        date: weekDates[1],
        values: template.slice(),
      },
      {
        name: 'Onsdag',
        date: weekDates[2],
        values: template.slice(),
      },
      {
        name: 'Torsdag',
        date: weekDates[3],
        values: template.slice(),
      },
      {
        name: 'Fredag',
        date: weekDates[4],
        values: template.slice(),
      },
      {
        name: 'Lørdag',
        date: weekDates[5],
        values: template.slice(),
      },
      {
        name: 'Søndag',
        date: weekDates[6],
        values: template.slice(),
      },
    ];

    data.forEach((x) => {
      const parts = minutesBetween(x.startTime, x.endTime) / 15;
      const startIndex =
        Number(x.startTime.split('.')[0]) * 4 + Number(x.startTime.split('.')[1]) / 15;
      const day = internalData.find((y) => y.date === x.date.substring(0, 10));
      if (!day) return;

      for (let i = 0; i < parts; i += 1) {
        // Only update if the new category is higher than the current (to solve overlapping data)
        if (day.values[startIndex + i] < x.category) day.values[startIndex + i] = x.category;
      }
    });

    return internalData;
  }, [data, weekDates]);

  return (
    <div className="grid w-fit max-w-[500px] grid-cols-8 p-[12px]">
      <div className="-mt-[6px] flex flex-col gap-[25px] pr-[4px] text-right text-[10px] font-semibold">
        {Array.from({ length: 24 }, (_item, idx) => idx).map((x) => {
          if (x % 2 === 1) return null;
          return <div key={x}>{`${x}:00`}</div>;
        })}
      </div>
      {weekData.map((day) => (
        <div key={day.name}>
          <div key={day.name} className="h-fit border border-[#f3f4f6]">
            {day.values.map((x, idx) => {
              const border = idx % 4 === 0 ? 'border-t border-[#f3f4f6]' : '';

              if (x === 1)
                return (
                  <div
                    key={`${day.name}-${idx}`}
                    className={clsx('h-[5px] bg-[#619b72]', border)}
                  />
                );
              if (x === 2)
                return (
                  <div
                    key={`${day.name}-${idx}`}
                    className={clsx('h-[5px] bg-[#e9aa4f]', border)}
                  />
                );
              if (x === 3)
                return (
                  <div
                    key={`${day.name}-${idx}`}
                    className={clsx('h-[5px] bg-[#700c11]', border)}
                  />
                );

              return (
                <div key={`${day.name}-${idx}`} className={clsx('h-[5px] bg-[#ffffff]', border)} />
              );
            })}
          </div>
          <span className="flex justify-center pt-[2px] text-[10px] font-semibold">{day.name}</span>
        </div>
      ))}
    </div>
  );
}

// ----- PieChartComponent -----

function PieChartComponent({ data }) {
  const categoriesInMinutes = data.map((item) => {
    const startTime = item.startTime.split('.');
    const endTime = item.endTime.split('.');

    const start = Number(startTime[0]) * 60 + Number(startTime[1]);
    const end = Number(endTime[0]) * 60 + Number(endTime[1]);

    if (end < start) {
      const minutes = 24 * 60 - start + end;
      return { category: item.category, value: minutes };
    }

    const minutes = end - start;
    return { category: item.category, value: minutes };
  });

  // Could be optimized, but not necessary for now
  const calcTotalOfEachCategory = () => {
    const green = categoriesInMinutes
      .filter((x) => x.category === 1)
      .reduce((acc, curr) => acc + curr.value, 0);
    const yellow = categoriesInMinutes
      .filter((x) => x.category === 2)
      .reduce((acc, curr) => acc + curr.value, 0);
    const red = categoriesInMinutes
      .filter((x) => x.category === 3)
      .reduce((acc, curr) => acc + curr.value, 0);

    const result = [
      { name: 'Green', value: green },
      { name: 'Yellow', value: yellow },
      { name: 'Red', value: red },
    ];

    return result.filter((x) => x.value > 0);
  };

  const mappedData = calcTotalOfEachCategory();

  return (
    <PieChart width={250} height={250}>
      <Pie
        data={mappedData}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={100}
        fill="#8884d8"
        dataKey="value"
      >
        {mappedData.map((_entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[_entry.name.toLowerCase()]} />
        ))}
      </Pie>
    </PieChart>
  );
}
