const sizes = {
  md: "w-5 h-5 border-2",
  lg: "w-8 h-8 border-4",
  xl: "w-12 h-12 border-4",
};

export default function Spinner({ size = "md" }) {
  const sizeClasses = sizes[size];

  return (
    <div
      className={`animate-spin rounded-full border-darkgreen border-t-transparent border-r-transparent ${sizeClasses}`}
    />
  );
}
