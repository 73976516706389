import Selector from "../components/RadioSelect";
import Textarea from "../components/Textarea";
import { useEffect, useMemo, useState } from "react";
import AcceptButton from "../components/AcceptButton";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import api, { fetcher } from "../utils/api";
import Spinner from "../components/Spinner";
import Button from "../components/Button";

const selectors = [
    {
        id: 1,
        title: "1. Træthed",
        options: [
            {
                id: '1',
                value: 0,
                title: '0: Jeg har slet ikke følt mig træt (aldrig unormalt træt, ikke behov for at hvile mere end sædvanligt).'
            },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg har været træt flere gange om dagen, men jeg bliver mere frisk af at hvile.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg har følt mig træt det meste af dagen og hvile har ingen eller kun lidt effekt.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            { id: '7', value: 3, title: '3: Jeg føler mig altid træt og hvile har ingen effekt.' },
        ],
        description: <><p className="my-3">Har du følt dig træt den seneste måned? Det betyder ikke noget, om det er
            fysisk (muskulær træthed) eller træt i hovedet. Hvis der for nylig er sket noget uvant (fx en ulykke eller
            kortvarig sygdom) skal du forsøge at se bort fra det.</p> </>
    },
    {
        id: 2,
        options: [
            { id: '1', value: 0, title: '0: Jeg har ingen vanskeligheder med at komme i gang med aktiviteter.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg har sværere end tidligere ved at komme i gang med aktiviteter. Jeg udskyder det gerne.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Det kræver en stor anstrengelse for mig at komme i gang med aktiviteter. Dette gælder også dagligdags aktiviteter, som at stå ud af sengen, vaske mig osv.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Jeg kan ikke få de enkle hverdagsaktiviteter gjort (spise, tage tøj på). Jeg må have hjælp til alt.'
            },
        ],
        title: "2. Manglende initiativ",
        description: <><p className="my-3">Har du svært ved at komme i gang med aktiviteter? Føler du dig initiativløs
            og er det svært at komme i gang med noget, uanset om det er en ny opgave eller det drejer sig om ting, som
            du gør hver dag.</p></>
    },
    {
        id: 3,
        options: [
            {
                id: '1',
                value: 0,
                title: '0: Jeg kan blive ved som før. Min  udholdenhed ved ”hjernearbejde” er ikke blevet mindre.'
            },
            { id: '2', value: 0.5, title: '0.5' },
            { id: '3', value: 1, title: '1: Jeg bliver hurtigere træt, men kan udføre ”hjernearbejde” som tidligere.' },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg bliver hurtigt træt og bliver nødt til at holde pause eller gøre noget andet oftere end jeg plejer.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Jeg bliver så let træt, at jeg ikke kan gøre noget som helst eller må afbryde alle aktiviteter efter kort tid (ca. 5 minutter).'
            },
        ],
        title: "3. Mental træthed",
        description: <><p className="my-3">Bliver du hurtigt træt ”i hovedet”, når hjernen skal arbejde? Bliver du
            mentalt træt af aktiviteter, som at læse, se TV eller deltage i samtale med flere personer. Er du nødt til
            at holde pauser eller skifte aktivitet?</p> </>
    },
    {
        id: 4,
        options: [
            { id: '1', value: 0, title: '0: Jeg har brug for mindre end en times hvile for at kunne arbejde videre.' },
            { id: '2', value: 0.5, title: '0.5' },
            { id: '3', value: 1, title: '1: Jeg har brug for at hvile mere end en time, men behøver ikke en nats søvn.' },
            { id: '4', value: 1.5, title: '1.5' },
            { id: '5', value: 2, title: '2: Jeg har brug for en nats søvn for at kunne fortsætte med at arbejde.' },
            { id: '6', value: 2.5, title: '2.5' },
            { id: '7', value: 3, title: '3: Jeg har brug for flere dages hvile for at ”komme mig”.' },
        ],
        title: "4. Mental genopretning",
        description: <><p className="my-3">Hvis du er nødt til at holde pause, hvor lang tid tager det så for dig at
            blive klar igen, efter at du har arbejdet lige til ”du segner” eller til du har mistet evnen til at
            koncentrere dig om det du er i gang med.</p> </>
    },
    {
        id: 5,
        options: [
            { id: '1', value: 0, title: '0: Jeg kan koncentrere mig som tidligere.' },
            { id: '2', value: 0.5, title: '0.5' },
            { id: '3', value: 1, title: '1: Jeg mister nogle gange koncentrationen, fx når jeg læser eller ser TV.' },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg har så svært ved at koncentrere mig, at det er vanskeligt for mig fx at læse en avis eller at deltage i en samtale med flere personer.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Jeg har altid så svært ved at koncentrere mig, at det næsten er umuligt for mig at gøre noget som helst.'
            },
        ],
        title: "5. Koncentrationsbesvær",
        description: <><p className="my-3">Har du svært ved at samle tankerne og ved at koncentrere dig?</p> </>
    },
    {
        id: 6,
        options: [
            { id: '1', value: 0, title: '0: Jeg har ingen problemer med hukommelse.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg glemmer lidt oftere end jeg synes at jeg burde, men jeg kan klare mig ved at bruge huskesedler.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Min dårlige hukommelse er grund til regelmæssigt besvær (fx at jeg glemmer vigtige møder eller at slukke for komfuret).'
            },
            { id: '6', value: 2.5, title: '2.5' },
            { id: '7', value: 3, title: '3: Jeg kan næsten ikke huske noget som helst.' },
        ],
        title: "6. Hukommelsesbesvær",
        description: <><p className="my-3">Glemmer du oftere end tidligere og har du brug for huskesedler, eller må du
            lede mere efter ting hjemme og på arbejde?</p> </>
    },
    {
        id: 7,
        options: [
            { id: '1', value: 0, title: '0: Jeg føler mig hverken træg eller langsom ved ”tankearbejde”.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Ved krævende ”tankearbejde” føler jeg en eller flere gange i løbet af dagen en vis langsomhed.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg føler mig ofte træg og langsom i ”tankearbejde”, selv ved daglige gøremål, som fx ved samtale med en person eller ved avislæsning.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            { id: '7', value: 3, title: '3: Jeg føler mig altid meget træg og langsom i mine tanker.' },
        ],
        title: "7. Tanketræghed",
        description: <><p className="my-3">Føler du dig træg eller langsom i ”tankearbejde”? Føler du, at det tager
            ualmindelig lang tid at afslutte en tankegang eller at løse en opgave, som kræver tankearbejde.</p> </>
    },
    {
        id: 8,
        options: [
            { id: '1', value: 0, title: '0: Jeg klarer stress lige så godt som tidligere.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg bliver lettere stresset, men kun i krævende situationer, som jeg tidligere kunne klare.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg bliver lettere stresset end tidligere. Jeg bliver stresset i situationer, som tidligere ikke generede mig.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Jeg bliver meget let stresset. Jeg føler mig stresset i ukendte eller anstrengende situationer.'
            },
        ],
        title: "8. Stress følsomhed",
        description: <><p className="my-3">Har du haft svært ved at klare stress, altså at gøre flere ting samtidigt og
            under tidspres?</p> </>
    },
    {
        id: 9,
        options: [
            { id: '1', value: 0, title: '0: Jeg er ikke mere følsom end tidligere.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg er mere følsom end andre mennesker, men det er stadig naturligt for mig. Jeg kommer let til at græde eller få tårer i øjnene, men det sker kun ved ting, som påvirker mig stærkt.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Min følsomhed er til besvær og er generende. Nogen gange begynder jeg at græde, selv over ting som ikke betyder noget for mig. Jeg forsøger at undgå visse situationer på grund af dette.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Min følsomhed er et stort problem for mig. Den forstyrrer mine daglige relationer med min nærmeste familie og gør det svært for mig at klare mig udenfor hjemmet.'
            },
        ],
        title: "9. Øget emotionalitet",
        description: <><p className="my-3">Har du lettere ved at komme til at græde end tidligere? Kommer du ofte til at
            græde, når du fx ser en sørgelig film, eller når du taler med dine pårørende. Hvis der for nylig er sket
            noget usædvanligt (fx en ulykke eller kortvarig sygdom), skal du forsøge at se bort fra det.</p> </>
    },
    {
        id: 10,
        options: [
            { id: '1', value: 0, title: '0: Jeg føler mig ikke mere ”kort luntet” eller irritabel end tidligere.' },
            { id: '2', value: 0.5, title: '0.5' },
            { id: '3', value: 1, title: '1: Jeg bliver lettere irriteret, men det går hurtigt over.' },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg bliver meget hurtigt irriteret over bagateller eller over ting, som andre ikke bekymrer sig om.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Jeg reagerer med voldsom vrede eller raseri. Jeg har svært ved at beherske det.'
            },
        ],
        title: "10. Irritabilitet eller ”kort lunte”",
        description: <><p className="my-3">Kommer du let til at føle dig ”kort luntet” eller blive irriteret over ting,
            som du tidligere syntes var bagateller?</p></>
    },
    {
        id: 11,
        options: [
            { id: '1', value: 0, title: '0: Jeg har ikke øget følsomhed for lys.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg kan indimellem opleve problemer med stærkt lys, fx sollys, reflekser fra sne, vand, vinduer eller stærke indendørs lamper. Men det kan let afhjælpes, fx med solbriller.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg er så følsom overfor lys, at jeg foretrækker at udføre mine daglige aktiviteter i dæmpet belysning. Jeg har svært ved at gå ud uden at bruge solbriller.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Min lysfølsomhed er så stærk, at jeg ikke kan forlade hjemmet uden at have solbriller på. Jeg har altid trukket gardiner for (eller lignende).'
            },
        ],
        title: "11. Overfølsomhed for lys",
        description: <><p className="my-3">Er du følsom overfor stærkt lys?</p> <p className="my-3"></p><p
            className="my-3"></p> <p className="my-3"></p></>
    },
    {
        id: 12,
        options: [
            { id: '1', value: 0, title: '0: Jeg har ikke øget følsomhed overfor lyd.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg har nogle gange besvær med høje lyde (fx musik, støj fra TV og radio eller pludselige uventede lyde), men jeg kan klare det ved at skrue ned for lyden. Min følsomhed overfor lyd forstyrrer mig ikke i min hverdag.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg er helt klart lydoverfølsom. Jeg er nødt til at undgå høje lyde eller at dæmpe dem (fx med ørepropper) for at kunne klare hverdagen.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Min følsomhed overfor lyd er så stor, at jeg har svært at klare mig i hjemmet, trods lyddæmpning.'
            },
        ],
        title: "12. Overfølsomhed for lyd",
        description: <><p className="my-3">Er du følsom overfor lyd?</p> </>
    },
    {
        id: 13,
        options: [
            { id: '1', value: 0, title: '0: Jeg sover ikke mindre end tidligere.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg har lette problemer med at falde i søvn eller jeg sover i kortere tid, sover lettere eller mere uroligt end tidligere.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg sover mindst to timer mindre end tidligere og vågner flere gange i løbet af natten, uden at noget har forstyrret mig.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            { id: '7', value: 3, title: '3: Jeg sover mindre end to til tre timer pr. nat.' },
        ],
        title: "13. Mindsket nattesøvn",
        description: <><p className="my-3"> Sover du dårligt om natten? Hvis du sover mere om natten end tidligere,
            scores dette som ”0”. Hvis du tager sovemedicin og sover normalt, scores dette som ”0”.</p> </>
    },
    {
        id: 14,
        options: [
            { id: '1', value: 0, title: '0: Jeg sover ikke mere end tidligere.' },
            { id: '2', value: 0.5, title: '0.5' },
            {
                id: '3',
                value: 1,
                title: '1: Jeg sover i længere tid eller sover dybere, men mindre end to timer mere end tidligere, inkl. søvn i løbet af dagen.'
            },
            { id: '4', value: 1.5, title: '1.5' },
            {
                id: '5',
                value: 2,
                title: '2: Jeg sover længere og dybere. Mindst to timer mere end tidligere, inkl. søvn i løbet af dagen.'
            },
            { id: '6', value: 2.5, title: '2.5' },
            {
                id: '7',
                value: 3,
                title: '3: Jeg sover længere og dybere. Mindst fire timer mere end tidligere og derudover er jeg nødt til at sove i løbet af dagen.'
            },
        ],
        title: "14. Øget søvn",
        description: <><p className="my-3"> Sover du mere og /eller dybere end sædvanligt? Hvis du sover mindre end
            tidligere markeres dette som ”0”. Obs! Medregn også søvn om dagen.</p> </>
    }
]

const lastSelector = [
    {
        id: 15,
        title: "15. Døgnvariationer",

        description: <><p className="my-3"> Er der visse tider på døgnet, hvor de problemer, som vi har spurgt om (fx
            træthed, koncentration) er større eller mindre?</p> </>
    }
]

const daySelector = [
    {
        id: 16,
        title: "Hvornår har du det bedst?",
        options: [
            { id: '1', value: 0, title: 'Formiddag' },
            { id: '2', value: 1, title: 'Eftermiddag' },
            { id: '3', value: 2, title: 'Aften' },
            { id: '4', value: 3, title: 'Nat' },


        ],
        description: <><p className="my-3"> Er der visse tider på døgnet, hvor de problemer, som vi har spurgt om (fx
            træthed, koncentration) er større eller mindre?</p> </>
    },
    {
        id: 17,
        title: "Hvornår har du det dårligst",
        options: [
            { id: '1', value: 0, title: 'Formiddag' },
            { id: '2', value: 1, title: 'Eftermiddag' },
            { id: '3', value: 2, title: 'Aften' },
            { id: '4', value: 3, title: 'Nat' },


        ],
        description: <><p className="my-3"> Er der visse tider på døgnet, hvor de problemer, som vi har spurgt om (fx
            træthed, koncentration) er større eller mindre?</p> </>
    }
]

const lastOptions = [
    {
        id: '1',
        value: 1,
        title: '1: Jeg har ikke lagt mærke til, at mine problemer er større eller mindre på visse tidspunkter og jeg har ikke nogle særlige problemer.'
    },
    {
        id: '2',
        value: 2,
        title: '2: Der er en klar forskel på forskellige tidspunkter på døgnet. Jeg kan forudse, at jeg vil føle mig bedre på visse tidspunkter og dårligere på andre tidspunkter.'
    },
    { id: '3', value: 3, title: '3: Jeg føler mig dårlig i alle døgnets timer.' },
]

const defaultValues = {
    answers: [],
    notice: "",
    clientUid: "",
    schemaType: 2
}

export default function MifPage() {
    const [formValues, setFormValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/`;
        navigate(path);
    }
    const [dayVariant, setDayVariant] = useState(false);
    const clientUid = useMemo(() => searchParams.get("clientUid"), [searchParams]);

    const { isLoading, isError } = useQuery(clientUid, () => fetcher(`clients/${clientUid}/survey/check`),
        { enabled: clientUid !== undefined, refetchOnWindowFocus: false, refetchOnReconnect: false })

    const { mutate, isSuccess } = useMutation(
        (values) => api.post(`clients/${clientUid}/survey`, values).then((res) => res.data)
    )

    useEffect(() => {
        setFormValues(formValues => ({ ...formValues, clientUid }))
    }, [clientUid, searchParams])

    useEffect(() => {
        if (formValues.answers.find(x => x.questionId === 15 && x.value > 1)) {
            setDayVariant(true);

        } else if (formValues.answers.find(x => x.questionId === 15 && x.value === 1)) {
            setDayVariant(false);

        }

    }, [formValues])

    if (isLoading)
        return <div className="flex items-center justify-center h-screen"><Spinner size="xl" /></div>

    if (isError)
        return <div className="flex items-center justify-center h-screen"><p>Kunne ikke finde bruger.</p></div>

    const onChangeHandler = (name, value) => {
        delete errors[name] // Remove error
        setFormValues(formValues => ({ ...formValues, [name]: value }))
    }

    const onSubmit = () => {
        var total = 15;
        if (dayVariant)
            total = 17;
        // Hvis fejl
        if (formValues.answers.length < total) {
            const errors = { answers: [] }
            const questionIds = formValues.answers.map(x => x.questionId)
            const numbers = Array.from({ length: selectors.length }, (_, i) => i + 1)
            numbers.forEach(element => {
                if (!questionIds.includes(element))
                    errors.answers.push(element)
            });

            setErrors(errors)
        } else {
            mutate(formValues)
        }
    }

    if (isSuccess)
        return (
            <div className="flex flex-col items-center justify-center h-screen"><h1
                className="text-3xl font-medium">Tusind
                tak
                for
                din indtastning!</h1>
                <Button color="green" onClick={() => routeChange()}>
                    <div className="p-2 font-barlow">Gå tilbage til Min Dag | Min Energi</div>
                </Button>

            </div>)

    return (
        <div className="overflow-x-auto flex justify-center items-center text-left flex-col font-barlow">
            <h1 className="text-3xl font-medium mb-7 p-4">Mental Fatigue Scale (MFS) - vejledning</h1>
            <div className=" w-full sm:w-[512px] text-lg  flex flex-col gap-4 mb-7 px-4 sm:px-0">
                <p>Vi er interesserede i din nuværende tilstand, dvs. hvordan du har haft det den sidste måned.</p>
                <p className="my-2">Når du skal sammenligne hvordan det var tidligere, skal du sammenligne det med
                    hvordan det var før du blev syg / blev skadet.</p>
                <p>I tabellen for hvert spørgsmål findes 4 påstande som beskriver ingen (0), let (1), middelsvært (2) og
                    udtalt besvær (3).</p>
                <p>Vi ønsker, at du markerer det tal som bedst beskriver dine problemer.
                    Hvis du synes, at du ligger mellem to påstande, markerer du tallet imellem de to påstande.</p>
                <ul>
                    <li>© Birgitta Johansson og Lars Rönnbäck</li>
                    <li>Oversat til dansk af Karen Marie Banke Uggerhøj og Birgitte</li>
                    <li>Fristrup</li>
                </ul>
            </div>
            <div className="bg-[#FAFAFA] w-full py-12 px-4">
                <div className="flex flex-col items-center">
                    <div className="sm:w-[512px]">
                        <form className="flex flex-col" onSubmit={e => e.preventDefault()}>
                            {selectors.map(x => <Selector key={x.id} title={x.title} name={x.id} options={x.options}
                                description={x.description} formValues={formValues}
                                setFormValues={setFormValues}
                                errors={errors} setErrors={setErrors} />)}
                            {lastSelector.map(x => <Selector key={x.id} title={x.title} name={x.id}
                                options={lastOptions}
                                description={x.description} formValues={formValues}
                                setFormValues={setFormValues}
                                errors={errors} setErrors={setErrors} />)}

                            {dayVariant ? daySelector.map(x => <Selector key={x.id} title={x.title} name={x.id}
                                options={x.options}
                                description={x.description}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                errors={errors} setErrors={setErrors} />) : ""}
                            <Textarea title="Bemærkninger" formValues={formValues} onChangeHandler={onChangeHandler} />
                            <AcceptButton color="lightgreen" size="xl" className="my-20"
                                onClick={() => onSubmit()}> Send Besvarelse </AcceptButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
