import { useRef, useState } from "react";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import useClientWorkspaceConsent from "../hooks/useClientWorkspaceConsent";
import useClientWorkspaceConsentRespond from "../hooks/useClientWorkspaceConsentRespond";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

const hStyles = (h) => h?.replaceAll("<h1>", "<h1 class='text-[24px]'>")
    .replaceAll("<h2>", "<h2 class='text-[20px]'>")
    .replaceAll("<h3>", "<h3 class='text-[16px]'>")
    .replaceAll("<h4>", "<h4 class='text-[15px]'>")
    .replaceAll("<h5>", "<h5 class='text-[13px]'>")
    .replaceAll("<h6>", "<h6 class='text-[12px]'>");

// Custom simple html renderer to match admin site
export const renderTextDangerously = (text) => (
    <div dangerouslySetInnerHTML={{ __html: hStyles(text?.replaceAll('\n', '<br/>')) }} />
);

export default function WorkplaceConsentPage() {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();

    const { data, isLoading, isError } = useClientWorkspaceConsent()
    const { mutateAsync, isLoading: mutateIsLoading } = useClientWorkspaceConsentRespond();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const submitConsent = (accepted) => {
        mutateAsync({ workspaceConsentId: data?.workplaceConsent.id, accepted }).then(() => {
            navigate("/", { replace: true });
        });
    }

    if (isError) return <div className="flex items-center justify-center h-screen">Der skete en fejl.</div>
    if (isLoading) return <div className="flex items-center justify-center h-screen">Henter..</div>

    return (
        <div className="flex justify-center flex-col">
            {/* <div className="flex justify-end px-12">
                <Button onClick={handlePrint}>Eksportér</Button>
            </div> */}
            <div ref={componentRef} className="max-w-[1000px] flex-col p-12 flex text-[13px]">
                <div className="hidden print:flex flex-row justify-between text-sm pb-4">
                    <span>Version {data?.workplaceConsent.version}</span>
                    <span>Dato: {new Intl.DateTimeFormat('da-DK', {
                        dateStyle: 'short',
                    }).format(new Date())}</span>
                </div>
                <h1 className="text-lg font-bold uppercase text-center text-[#4FA377]">
                    Sådan behandler vi dine persondata
                </h1>
                <div className="my-6">
                    {renderTextDangerously(data?.workplaceConsent?.text)}
                </div>
                <div className='flex flex-col print:hidden'>
                    {!data?.canModifyData &&
                        <div className='pt-8 pb-4'>
                            <Checkbox id="consent" name="consent" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} label={"Jeg accepterer ovenstående"} />
                        </div>
                    }
                    <div className="flex flex-col gap-4 items-center divide-y-[1px] divide-[#d1d5db]">
                        <div>

                            <div className="mt-4 flex justify-center gap-8">
                                <Button
                                    onClick={() => submitConsent(false)}
                                    disabled={mutateIsLoading}
                                >
                                    Afvis
                                </Button>
                                {!data?.canModifyData &&
                                    <Button
                                        onClick={() => submitConsent(true)}
                                        disabled={!isChecked || mutateIsLoading}
                                    >
                                        Accepter
                                    </Button>
                                }
                            </div>
                            <p className='text-xs text-center w-[400px]'>
                                OBS: Hvis du afviser at give accept til vores behandling af dine persondata, så kan du ikke længere registrere i systemet.
                            </p>
                        </div>
                        <div className="w-[350px] flex justify-center pt-4">
                            <Button onClick={handlePrint}>Eksportér</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
