import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import useClientWorkspaceConsent from '../hooks/useClientWorkspaceConsent';
import Checkbox from './Checkbox';
import Button from './Button';
import useClientWorkspaceConsentRespond from '../hooks/useClientWorkspaceConsentRespond';
import { renderTextDangerously } from '../pages/WorkplaceConsentPage';

export default function WorkplaceConsentDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const { data } = useClientWorkspaceConsent();
    const { mutateAsync, isLoading: mutateIsLoading } = useClientWorkspaceConsentRespond();

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (data && data?.shouldAsk) {
            setIsOpen(true)
        }
    }, [data])

    const submitConsent = (accepted) => {
        mutateAsync({ workspaceConsentId: data?.workplaceConsent.id, accepted }).then(() => { closeModal() });
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => undefined}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                                    Sådan behandler vi dine data
                                </Dialog.Title>
                                <div className='divide-y-[1px] divide-[#d1d5db]'>
                                    {/* <Dialog.Description as='div' className="text-xs text-gray-500 text-center">
                                        OBS: Hvis du afviser at give accept til vores behandling af dine persondata, så kan du ikke længere registrere i systemet.
                                    </Dialog.Description> */}
                                    <Dialog.Description as='div' className="pt-2 mt-2 text-sm text-gray-500 text-left">
                                        {renderTextDangerously(data?.workplaceConsent?.text)}
                                    </Dialog.Description>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='pt-8 pb-4'>
                                        <Checkbox id="consent" name="consent" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} label={"Jeg accepterer ovenstående"} />
                                    </div>
                                    <div className="mt-4 flex justify-center gap-8">
                                        <Button
                                            onClick={() => submitConsent(false)}
                                            disabled={mutateIsLoading}
                                        >
                                            Afvis
                                        </Button>
                                        <Button
                                            onClick={() => submitConsent(true)}
                                            disabled={!isChecked || mutateIsLoading}
                                        >
                                            Accepter
                                        </Button>
                                    </div>
                                    <p className='text-xs text-center'>
                                        OBS: Hvis du afviser at give accept til vores behandling af dine persondata, så kan du ikke længere registrere i systemet.
                                    </p>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
