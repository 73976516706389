export default function Input({
  type = "text",
  id,
  name,
  value,
  pattern,
  onChange,
  onKeyDown = () => {},
  placeholder = "",
  step = type === "time" ? 900 : "",
}) {
  return (
      <input
          type={type}
          name={name}
          id={id}
          pattern={pattern}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          step={step}
          required
          className="mt-1 focus:ring-lightgreen focus:border-lightgreen block w-full shadow-sm sm:text-sm border-gray rounded-md"
      />
  );
}
