import { useQuery } from "react-query";
import api from "../utils/api";

const getClientWorkspaceConsent = async () =>
  api.get(`clientWorkplaceConsent`).then((response) =>
    response.data
  )

export default function useClientWorkspaceConsent() {
  return useQuery("clientWorkplaceConsent", () => getClientWorkspaceConsent(), { refetchOnReconnect: false, refetchOnWindowFocus: false });
}
