const months = [
  'Januar',
  'Februar',
  'Marts',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'December',
];

export const timesOfDay = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

export const daysOfWeek = ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'];

export const getStartAndEndOfWeekAsISOString = (date) => {
  const first = date.getDate() - date.getDay() + 1; // First day is the day of the month - the day of the week (+1 for monday)
  const last = first + 6 + 1; // last day is the first day + 6 (+1 for monday)

  const startDate = new Date(date.setDate(first));
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(date.setDate(last));
  endDate.setHours(0, 0, 0, 0);
  return { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
};

export const getWeekString = (date) => {
  const currentDate = new Date(date.getTime());
  currentDate.setHours(0, 0, 0, 0);

  // Set date to the nearest Thursday (current date + 4 - current day number)
  // ISO week date weeks start on Monday, so the day number is 1 (Monday) to 7 (Sunday)
  currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));

  // January 1st of the year
  const yearStart = new Date(currentDate.getFullYear(), 0, 1);

  // Calculate full weeks to nearest Thursday
  const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);

  const year = currentDate.getFullYear();

  return `${year}-W${weekNumber.toString().padStart(2, '0')}`;
};

export const getDateOfISOWeek = (w, y) => {
  const simple = new Date(y, 0, 1 + (w - 1) * 7, 12);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
};

export const displayMonthAndYear = (date) => {
  const endDate = new Date(date);
  endDate.setDate(date.getDate() + 6);
  if (date.getMonth() === endDate.getMonth())
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  return `${months[date.getMonth()]} ${date.getFullYear()} / ${
    months[endDate.getMonth()]
  } ${endDate.getFullYear()}`;
};

export const getDateFromWeek = (week) => {
  const oldWeek = parseInt(week.split('-')[1].substring(1), 10);
  const year = week.split('-')[0];
  return getDateOfISOWeek(oldWeek, year);
};
