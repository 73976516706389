export default function Checkbox({ id, name, checked, onChange, label }) {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        className="h-5 w-5 cursor-pointer text-lightgreen hover:accent-green focus:ring-0"
      />
      {label && (
        <label htmlFor={name} className="pl-2 font-barlow text-sm">
          {label}
        </label>
      )}
    </div>
  );
}
