import { useQuery } from "react-query";
import useActivities from "../hooks/useActivities";
import useSymptoms from "../hooks/useSymptoms";
import api from "../utils/api";
import RegistrationLabel from "./RegistrationLabel";
import Spinner from "./Spinner";
import { useAuth } from "../contexts/AuthContext";

export default function RegistrationList({ datefilter, setEditRegistration }) {
  const { isFetched: isFetchedActivities } = useActivities()
  const { isFetched: isFetchedSymptoms } = useSymptoms()
  const { user } = useAuth();

  const { isLoading, isIdle, error, data } = useQuery("registrations", () =>
    api.get(`clients/${user.uid}/registrations/overview`).then((res) => res.data), { refetchOnReconnect: false, refetchOnWindowFocus: false, enabled: isFetchedActivities && isFetchedSymptoms }
  );

  if (isLoading || isIdle)
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner size="lg" />
      </div>
    );

  if (error)
    return (
      <div className="flex justify-center italic">Der skete en fejl.</div>
    );

  if (data.length === 0) {
    return (
      <div className="flex justify-center items-center h-full italic">
        Der eksisterer ingen registreringer endnu.
      </div>
    );
  }

  const sortByStartTime = (a, b) => a.endTime > a.startTime ?
    a.startTime.replace(":", "") - b.startTime.replace(":", "") : a.endTime.replace(":", "") - b.endTime.replace(":", "");

  const sameDay = (d1, d2) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();


  const dayBefore = (d1, d2) => {
    const dayBeforeDate = d2
    dayBeforeDate.setDate(dayBeforeDate.getDate() - 1);
    return d1.getFullYear() === dayBeforeDate.getFullYear() &&
      d1.getMonth() === dayBeforeDate.getMonth() &&
      d1.getDate() === dayBeforeDate.getDate();
  }

  const mappedData = data
    .filter((d) => sameDay(new Date(d.date), new Date(datefilter)) || (dayBefore(new Date(d.date), new Date(datefilter)) && d.startTime > d.endTime))
    .sort(sortByStartTime)
    .map((d, i) => (
      <RegistrationLabel
        key={i}
        id={d.id}
        date={datefilter}
        activity={d.activityId}
        startTime={d.startTime}
        endTime={d.endTime}
        symptoms={d.symptoms}
        category={d.category}
        setEditRegistration={setEditRegistration}
      />
    ));

  return (
    <div className="flex flex-1 h-full flex-col space-y-2 w-full break-words max-w-[425px] sm:max-w-[600px]">
      {mappedData.length > 0 ? (
        mappedData
      ) : (
        <div className="flex justify-center items-center h-full italic">
          Der eksisterer ingen registreringer på denne dato..
        </div>
      )}
    </div>
  );
}
