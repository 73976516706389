import { useMemo, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import api, { fetcher } from "../utils/api";
import Spinner from "../components/Spinner"
import Button from "../components/Button";
import Textarea from "../components/Textarea";
import Selector from "../components/RadioSelect"
import AcceptButton from "../components/AcceptButton";

const selectors = [
    { id: 1, title: "1. Jeg føler mig veloplagt" },
    { id: 2, title: "2. Fysisk kan jeg ikke gøre ret meget" },
    { id: 3, title: "3. Jeg føler mig meget aktiv" },
    { id: 4, title: "4. Jeg har lyst til at gøre alle mulige rare ting" },
    { id: 5, title: "5. Jeg føler mig træt" },
    { id: 6, title: "6. Jeg synes, jeg laver meget på en dag" },
    { id: 7, title: "7. Når jeg laver noget, kan jeg fastholde tankerne på det" },
    { id: 8, title: "8. Fysisk kan jeg overkomme meget" },
    { id: 9, title: "9. Jeg gruer for at skulle lave noget" },
    { id: 10, title: "10. Jeg synes, jeg laver meget lidt på en dag" },
    { id: 11, title: "11. Jeg kan sagtens koncentrere mig" },
    { id: 12, title: "12. Jeg er udhvilet" },
    { id: 13, title: "13. Jeg skal anstrenge mig meget for at koncentrere mig om noget" },
    { id: 14, title: "14. Fysisk føler jeg mig i dårlig form" },
    { id: 15, title: "15. Jeg har mange planer" },
    { id: 16, title: "16. Jeg bliver nemt træt" },
    { id: 17, title: "17. Jeg får næsten ikke lavet noget" },
    { id: 18, title: "18. Jeg har ikke lyst til at lave noget" },
    { id: 19, title: "19. Jeg bliver let adspredt" },
    { id: 20, title: "20. Fysisk føler jeg mig i vældig god form" }
]

const options = [
    { id: 1, value: 1, title: '1 Ja, det er rigtigt' },
    { id: 2, value: 2, title: '2 ...' },
    { id: 3, value: 3, title: '3 ...' },
    { id: 4, value: 4, title: '4 ...' },
    { id: 5, value: 5, title: '5 Nej, det er ikke rigtigt' },
]

const defaultValues = {
    answers: [],
    notice: "",
    clientUid: "",
    schemaType: 1
}

export default function MifPage() {
    const [formValues, setFormValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/`;
        navigate(path);
    }

    const clientUid = useMemo(() => searchParams.get("clientUid"), [searchParams]);

    const { isLoading, isError } = useQuery(clientUid, () => fetcher(`clients/${clientUid}/survey/check`),
        { enabled: clientUid !== undefined, refetchOnWindowFocus: false, refetchOnReconnect: false })

    const { mutate, isSuccess } = useMutation(
        (values) => api.post(`clients/${clientUid}/survey`, values).then((res) => res.data)
    )

    useEffect(() => {
        setFormValues(formValues => ({ ...formValues, clientUid }))
    }, [clientUid, searchParams])

    if (isLoading)
        return <div className="flex items-center justify-center h-screen"><Spinner size="xl" /></div>

    if (isError)
        return <div className="flex items-center justify-center h-screen"><p>Kunne ikke finde bruger.</p></div>

    const onChangeHandler = (name, value) => {
        delete errors[name] // Remove error
        setFormValues(formValues => ({ ...formValues, [name]: value }))
    }

    const onSubmit = () => {
        // Hvis fejl
        if (formValues.answers.length < 20) {
            const errors = { answers: [] }
            const questionIds = formValues.answers.map(x => x.questionId)
            const numbers = Array.from({ length: selectors.length }, (_, i) => i + 1)
            numbers.forEach(element => {
                if (!questionIds.includes(element))
                    errors.answers.push(element)
            });

            setErrors(errors)
        } else {
            mutate(formValues)
        }
    }


    if (isSuccess)
        return (
            <div className="flex flex-col items-center justify-center h-screen"><h1
                className="text-3xl font-medium">Tusind
                tak
                for
                din indtastning!</h1>
                <Button color="green" onClick={() => routeChange()}>
                    <div className="p-2 font-barlow">Gå tilbage til Min Dag | Min Energi</div>
                </Button>
            </div>)

    return (
        <div className="overflow-x-auto flex items-center justify-center text-left flex-col font-barlow">
            <div className="my-12 p-4">
                <h1 className="text-3xl font-medium">Multidimensional Fatigue Inventory </h1>
                <h1 className="text-3xl font-medium">(MFI-20) - vejledning</h1>
                <div className="w-full sm:w-[512px] text-lg flex flex-col gap-4">
                    <p>Vi er interesserede i at høre om din nuværende tilstand, dvs.
                        hvordan du har haft det generelt over den sidste måned.</p>
                    <p className="my-2">{"----"}</p>
                    <p>Ved hjælp af de følgende udsagn vil vi gerne have et indtryk af,
                        hvordan du har haft det i den senere tid.
                        Det er f.eks. udsagnet:
                        'Jeg føler mig afslappet'</p>
                    <p>Hvis du mener, at det er fuldstændig rigtigt, dvs. at du har følt
                        dig afslappet i den senere tid, så skal du sætte kryds i '1: Ja, det
                        er rigtigt'.</p>
                    <p>Jo mere du er uenig i udsagnet, jo mere i retningen af '5: Nej,
                        det er ikke rigtigt' skal du placere krydset. Du bedes tage stilling
                        til alle udsagnene og sætte et kryds ud for hvert udsagn.</p>
                    <ul>
                        <li className="flex gap-6">1 <span>Ja, det er rigtigt</span></li>
                        <li className="flex gap-6">2 <span>...</span></li>
                        <li className="flex gap-6">3 <span>...</span></li>
                        <li className="flex gap-6">4 <span>...</span></li>
                        <li className="flex gap-6">5 <span>Nej, det er ikke rigtigt</span></li>
                    </ul>
                    <div>
                        <p>© E. Smets, B. Garssen, B. Bonke</p>
                        <p>Oversat til dansk af T. Watt, M. Grønvold m.fl.</p>
                    </div>
                </div>
            </div>
            <div className="bg-[#FAFAFA] w-full py-12 px-4">
                <div className="flex flex-col items-center">
                    <div className="sm:w-[512px]">
                        <form className="flex flex-col" onSubmit={e => e.preventDefault()}>
                            {selectors.map(x => <Selector key={x.id} title={x.title} name={x.id} options={options}
                                formValues={formValues} setFormValues={setFormValues}
                                errors={errors} setErrors={setErrors} />)}
                            <Textarea title="Bemærkninger" formValues={formValues} onChangeHandler={onChangeHandler} />
                            <AcceptButton color="lightgreen" size="xl" className="my-20"
                                onClick={() => onSubmit()}> Send Besvarelse </AcceptButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
