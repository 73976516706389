import { ArrowsExpandIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { displayMonthAndYear, getDateOfISOWeek, getStartAndEndOfWeekAsISOString, getWeekString } from './WeekOverviewHelpers';
import WeekOverviewDiagrams from './WeekOverviewDiagrams';
import { useQuery } from 'react-query';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../utils/api';
import useActivities from '../../hooks/useActivities';
import useSymptoms from '../../hooks/useSymptoms';
import WeekOverviewCalendar from './WeekOverviewCalendar';

export default function WeekOverview() {
  const ref = useRef();
  const [data, setData] = useState([]);
  const [week, setWeek] = useState(getWeekString(new Date()));
  const [showAsDiagram, setShowAsDiagram] = useState(false);
  const { user } = useAuth();

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const { isFetched: isFetchedActivities } = useActivities()
  const { isFetched: isFetchedSymptoms } = useSymptoms()

  const { data: regstrations } = useQuery("registrations/overview", () =>
    api.get(`clients/${user.uid}/registrations/overview`).then((res) => res.data), { refetchOnReconnect: false, refetchOnWindowFocus: false, enabled: isFetchedActivities && isFetchedSymptoms }
  );

  useEffect(() => {
    if (regstrations) {
      const mappedData = [];
      regstrations
        .filter((y) => y.category !== 0)
        .forEach((z) => {
          const startTime = z.startTime.split('.');
          const endTime = z.endTime.split('.');
          const start = Number(startTime[0]) * 4 + Number(startTime[1]) / 15;
          const end = Number(endTime[0]) * 4 + Number(endTime[1]) / 15;

          // If the registration is within the same day
          if (start <= end) {
            mappedData.push(z);
            return;
          }

          // If the registration overlaps to the next day - split it into two
          mappedData.push({ ...z, endTime: '24.00' });
          const nextDate = new Date(z.date);
          nextDate.setDate(nextDate.getDate() + 1);
          mappedData.push({ ...z, startTime: '00.00', date: nextDate.toISOString() });
        });

      setData(mappedData);
    }
  }, [regstrations]);

  const getDataForWeek = (weekFor) => {
    const dateOfWeek = getDateOfISOWeek(weekFor.split('-')[1].substring(1), weekFor.split('-')[0]);
    const { startDate: startDateInWeek, endDate: endDateInWeek } =
      getStartAndEndOfWeekAsISOString(dateOfWeek);

    return data.filter((x) => x.date >= startDateInWeek && x.date <= endDateInWeek);
  };

  const getDateFromWeek = () => {
    const oldWeek = parseInt(week.split('-')[1].substring(1), 10);
    const year = week.split('-')[0];
    return getDateOfISOWeek(oldWeek, year);
  };

  const changeWeek = (change) => {
    const date = getDateFromWeek();
    date.setDate(date.getDate() + 7 * change);
    setWeek(getWeekString(date));
  };

  const displayWeekWithoutLeading0 = () =>
    week.split('-')[1].substring(1).startsWith('0')
      ? week.split('-')[1].substring(2)
      : week.split('-')[1].substring(1);

  const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;

  return (
    <div ref={ref} className="flex h-full w-full flex-col bg-white p-[2px]">
      <div className='text-center mt-1 mb-2 font-semibold md:hidden block'>OBS! Visning af ugeoversigt som kalender fungerer bedst på computer.</div>
      <header className="flex flex-wrap items-center justify-between border-b border-gray-200 bg-white py-[4px] px-[6px] lg:w-[1024px]">
        <h1 className="text-lg font-semibold text-gray-900 pr-4">
          <time dateTime="2022-01">{displayMonthAndYear(getDateFromWeek())}</time>
        </h1>
        <div className="flex items-center text-sm">
          <div className="flex flex-wrap items-center gap-[8px] rounded-md shadow-sm md:items-stretch">
            <button
              type="button"
              className="flex items-center justify-center rounded border border-gray-300 bg-white p-[8px] text-gray-700 hover:bg-gray-50 hover:text-gray-500"
              onClick={handlePrint}
            >
              Eksportér som diagram
            </button>
            <button
              type="button"
              className="flex items-center justify-center rounded border border-gray-300 bg-white p-[8px] text-gray-700 hover:bg-gray-50 hover:text-gray-500"
              onClick={() => {
                setShowAsDiagram((prev) => !prev);
              }}
            >
              Vis ugeoversigt som {showAsDiagram ? 'kalender' : 'diagram'}
            </button>
            {isChrome && (
              <input
                type="week"
                name="week"
                id="week"
                value={week}
                onChange={(e) => {
                  if (e.target.value) setWeek(e.target.value);
                }}
                className="flex items-center justify-center rounded border border-gray-300 bg-white p-[8px] text-gray-700 text-sm"
              />
            )}
            <span className="flex flex-row">
              <button
                type="button"
                className="flex w-[36px] items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white p-[8px] text-gray-700 hover:bg-gray-50 hover:text-gray-500"
                onClick={() => {
                  changeWeek(-1);
                }}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-[20px] w-[20px]" aria-hidden="true" />
              </button>
              <div className="block whitespace-nowrap border-t border-b border-gray-300 bg-white px-[3.5px] py-[8px] text-sm font-medium text-gray-700">
                Uge {displayWeekWithoutLeading0()}
              </div>
              <span className="relative -mx-px h-[5px] w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex w-[36px] items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white p-[8px] text-gray-700 hover:bg-gray-50 hover:text-gray-500"
                onClick={() => {
                  changeWeek(+1);
                }}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-[20px] w-[20px]" aria-hidden="true" />
              </button>
            </span>
            <button
              type="button"
              className="flex w-[36px] items-center justify-center rounded border border-gray-300 bg-white p-[8px] text-gray-700 hover:bg-gray-50 hover:text-gray-500"
              onClick={() => {
                if (!document.fullscreenElement) ref.current.requestFullscreen();
                else if (document.exitFullscreen) document.exitFullscreen();
              }}
            >
              <ArrowsExpandIcon className="h-[20px] w-[20px]" aria-hidden="true" />
            </button>
          </div>
        </div>
      </header>
      {/* Using hidden to be able to print */}
      <div className={!showAsDiagram ? 'hidden' : ''}>
        <WeekOverviewDiagrams data={getDataForWeek(week)} week={week} ref={printRef} />
      </div>
      <div className={showAsDiagram ? 'hidden' : ''}>
        <WeekOverviewCalendar data={getDataForWeek(week)} week={week} />
      </div>
    </div>
  );
}
