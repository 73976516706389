const colors = {
  red: "bg-redcategory",
  yellow: "bg-yellowcategory",
  green: "bg-greencategory",
  0: "border-1 bg-grey",
  1: "bg-greencategory",
  2: "bg-yellowcategory",
  3: "bg-redcategory",
  default: "border-1 bg-grey",
};

export default function Category({
  color = "default",
  border,
  onClick,
  clickable = false,
  roundedFull
}) {
  const colorClasses = colors[color];
  const borderClasses = border ? "outline outline-2 outline-blue" : "";
  const clickableClasses = clickable ? "cursor-pointer" : "";
  const roundedFullClasses = roundedFull ? "rounded-2xl" : "rounded-r-2xl";

  return (
    <div
      className={`${colorClasses} h-full w-full ${roundedFullClasses} ${borderClasses} drop-shadow-md ${clickableClasses}`}
      onClick={onClick}
    />
  );
}
