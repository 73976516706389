import { useQuery } from "react-query";
import api from "../utils/api";
import { useAuth } from "../contexts/AuthContext";

const getReflectionSheet = async (uid) =>
  api.get(`clients/${uid}/reflectionsheet`).then(res => res.data)

export default function useReflectionSheet() {
  const { user } = useAuth()

  return useQuery("reflectionsheet", () => getReflectionSheet(user.uid), { refetchOnReconnect: false, refetchOnWindowFocus: false });
}
