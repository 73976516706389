import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Button from './Button';
import { Link } from 'react-router-dom';

export default function WorkplaceConsentRequiredDialog({ isOpen, setIsOpen }) {
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => undefined}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                    Du mangler at acceptere databehandling
                                </Dialog.Title>
                                <Dialog.Description as='div' className="mt-2 text-sm text-gray-500">
                                    <p>
                                        Klik på følgende link for at acceptere vores behandling af dine persondata og herefter kan fortsætte med at bruge systemet.
                                    </p>
                                </Dialog.Description>
                                <div className="mt-4 flex justify-center gap-8">
                                    <Button
                                        onClick={() => closeModal()}
                                    >
                                        Luk
                                    </Button>
                                    <Link to="/consent">
                                        <Button
                                            onClick={() => closeModal()}
                                        >
                                            Læs og acceptér vores databehandling
                                        </Button>
                                    </Link>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
