const colors = {
    lightgreen: "bg-lightgreen disabled:bg-lightgreen hover:bg-darkgreen",
    green: "bg-green disabled:bg-green hover:bg-darkgreen",
    darkgreen: "bg-darkgreen disabled:bg-darkgreen hover:bg-darkgreen",
    white: "bg-white hover:bg-darkgreen",
    lightgrey: "bg-lightgrey hover:bg-grey"
};

const sizes = {
    xs: "px-2.5 py-1.5",
    sm: "px-3 py-2",
    md: "px-4 py-2",
    lg: "px-4 py-2",
    xl: "px-6 py-3",
};
const roundeds = {
    md: "rounded-md",
    xl: "rounded-xl",
    "2xl": "rounded-2xl",
    full: "rounded-full"
};

export default function Button({
    type = "button",
    color = "lightgreen",
    rounded = "md",
    size = "md",
    onClick,
    disabled = false,
    fullWidth,
    children,
}) {
    const colorClasses = colors[color];
    const sizeClasses = sizes[size];
    const roundedClasses = roundeds[rounded];

    return (
        <button
            type={type}
            className={`${colorClasses} ${roundedClasses} ${sizeClasses} px-8 my-3 text-sm font-bold disabled:opacity-50 ${fullWidth ? 'w-full' : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
