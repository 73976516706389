import { useQuery } from "react-query";
import api from "../utils/api";
import Category from "./Category";
import { useAuth } from "../contexts/AuthContext";

export default function RegistrationLabel({
  id,
  activity,
  startTime,
  endTime,
  symptoms,
  category,
  date,
  setEditRegistration
}) {
  const { user } = useAuth();

  const { data: activitiesFromApiData } = useQuery("activities", () =>
    api.get(`clients/${user.uid}/activities`).then((response) =>
      response.data.map((a) => ({
        value: a.id,
        label: `${a.name} ${a.description.length > 0 ? ` (${a.description})` : ""
          }`,
        enabled: a.enabled,
        brainPause: a.brainPause
      }))
    ), { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  const { data: symptomsFromApiData } = useQuery("symptomsDifferent", () =>
    api.get(`clients/${user.uid}/symptoms`).then((response) =>
      response.data.map((s) => ({
        value: s.id,
        label: s.name,
        enabled: s.enabled
      }))
    ), { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  if (!activitiesFromApiData || !symptomsFromApiData) {
    return <div>Fejl.</div>;
  }

  const findActivity = activitiesFromApiData.find(
    (a) => a.value === activity
  )?.label;

  const splitActivity = findActivity
    ? findActivity.includes("(")
      ? findActivity.split("(")[0]
      : findActivity
    : "Ukendt";

  const mapSymptoms = symptomsFromApiData
    ? symptoms.map((s) => (
      <div key={s}>
        {symptomsFromApiData.find((a) => a.value === s)?.label ?? 'Ukendt'}
      </div>
    ))
    : "Ukendt";

  const model = {
    id,
    date,
    activity,
    startTime,
    endTime,
    symptoms,
    category
  }

  return (
    <div className="relative flex bg-lightgreen rounded-2xl text-[14px] cursor-pointer" onClick={() => { setEditRegistration(model) }}>
      <div className="flex divide-x divide-green px-2 py-2 w-full">
        <div className="flex flex-initial w-32">
          <div className="flex flex-col">
            <span>{splitActivity}</span>
            <span className="text-[12px]">{`${startTime}-${endTime}`}</span>
          </div>
        </div>
        <div className="flex-1 px-3">{mapSymptoms}</div>
      </div>
      <div className="flex-none w-12">
        <Category color={`${category}`} />
      </div>
    </div>
  );
}
