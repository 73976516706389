import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Spinner from "./Spinner";
import api from "../utils/api";

const guestUrls = ["/reset", "/mfi", "/mfs"]

export const ProtectRoute = ({ children }) => {
  const { isAuthenticated, isLoading, refreshAccessToken, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest &&
          (err.response.config.baseURL.startsWith(process.env.REACT_APP_API_BASE_URI) ||
            err.response.config.url.indexOf('://') === -1)
        ) {
          return refreshAccessToken()
            .then((access_token) => {
              // retry the original request
              err.config.__isRetryRequest = true;
              err.config.headers.Authorization = `Bearer ${access_token}`;
              return api.request(err.config);
            })
            .catch((err) => {
              // if you ever get an unauthorized response, logout the user
              logout()
            });
        }

        throw err;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && (location.pathname !== "/login" && guestUrls.findIndex(x => location.pathname.startsWith(x))) === -1) {
      navigate("/login", { replace: true });
    }
  }, [isLoading, isAuthenticated, location.pathname, navigate]);

  if (isLoading || (!isAuthenticated && (location.pathname !== "/login" && guestUrls.findIndex(x => location.pathname.startsWith(x))) === -1)) {
    return (
      <div className="flex h-screen justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  }
  return children;
};
