import Axios from 'axios';

export const fetcher = url => api.get(url).then(res => res.data);

const api = Axios.create({
   baseURL: process.env.REACT_APP_API_BASE_URI,
   headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': process.env.REACT_APP_API_BASE_URI.replace('/V1', ''),
   },
   withCredentials: true,
});

export default api;
