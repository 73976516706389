import React, { useEffect, useRef } from 'react';
import useActivities from '../../hooks/useActivities';
import useSymptoms from '../../hooks/useSymptoms';
import { daysOfWeek, getDateFromWeek, timesOfDay } from './WeekOverviewHelpers';
import { PauseIcon, StarIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function WeekOverviewCalendar({ data, week }) {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);

  const { data: activities } = useActivities()
  const { data: symptoms } = useSymptoms()

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  const displayWeekDays = () => {
    const date = getDateFromWeek(week);
    const weekStrings = [];
    for (let index = 0; index < 7; index += 1) {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + index);
      weekStrings.push({ day: daysOfWeek[newDate.getDay()], date: newDate.getDate() });
    }
    return weekStrings;
  };

  const findActivityName = (activityId) => {
    return activities.findIndex((a) => a.value === activityId) !== -1
      ? activities.find((a) => a.value === activityId).label
      : '';
  };

  const findActivity = (activityId) => {
    return activities.findIndex((a) => a.id === activityId) !== -1
      ? activities.find((a) => a.id === activityId)
      : '';
  };

  const findSymptomName = (symptomId) => {
    return symptoms.findIndex((s) => s.id === symptomId) !== -1
      ? symptoms.find((s) => s.id === symptomId).name
      : '';
  };

  const convertRegistrationToCalendarCard = (registration) => {
    const date = getDateFromWeek(week);
    const first = date.getDate() - date.getDay() + 1; // First day is the day of the month - the day of the week (+1 for monday)
    const last = first + 6 + 1; // last day is the first day + 6 (+1 for monday)
    const startDate = new Date(date.setDate(first));
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(date.setDate(last));
    endDate.setHours(0, 0, 0, 0);

    const registrationDate = new Date(Date.parse(registration.date));
    const day = registrationDate.getDay();

    const getWeekDayString = (weekDay) => {
      switch (weekDay) {
        case 0:
          return 'col-start-7';
        case 1:
          return 'col-start-1';
        case 2:
          return 'col-start-2';
        case 3:
          return 'col-start-3';
        case 4:
          return 'col-start-4';
        case 5:
          return 'col-start-5';
        case 6:
          return 'col-start-6';
        default:
          return '';
      }
    };

    const startTimeHour = registration.startTime.split('.')[0];
    const startTimeMinutes = registration.startTime.split('.')[1];
    const endTimeHour = registration.endTime.split('.')[0];
    const endTimeMinutes = registration.endTime.split('.')[1];

    const gridRowStartTime = Math.round(startTimeHour * 12 + (startTimeMinutes / 15) * 3) + 2;

    // 12 per hour or 3 per quarter
    let gridRowDuration =
      (endTimeHour - startTimeHour) * 12 + ((endTimeMinutes - startTimeMinutes) / 15) * 3;
    // If over midnight,
    let gridRowDurationSecond =
      registration.endTime.split('.')[0] * 12 + (registration.endTime.split('.')[1] / 15) * 3;

    const minGridRowDuration = 4;
    if (gridRowDuration < minGridRowDuration) gridRowDuration = minGridRowDuration;
    if (gridRowDurationSecond < minGridRowDuration) gridRowDurationSecond = minGridRowDuration;

    let bgColor;
    switch (registration.category) {
      case 1:
        bgColor = 'bg-[#619b72]';
        break;
      case 2:
        bgColor = 'bg-[#e9aa4f]';
        break;
      case 3:
        bgColor = 'bg-[#700c11]';
        break;
      default:
        bgColor = 'bg-[#f9fafb]';
        break;
    }

    const timeColor = 'text-white';
    const registrationTextColor = 'text-white';

    const activity = findActivity(registration.activityId);
    // eslint-disable-next-line no-nested-ternary
    const topRightIcon = activity.brainPause ? (
      <div className={classNames('relative h-20 w-20 rounded-full border-2 border-white', bgColor)}>
        <PauseIcon className="absolute inset-0 m-1 h-14 w-14 text-white" />
      </div>
    ) : activity.goalActivity ? (
      <div className={classNames('relative h-20 w-20 rounded-full border-2 border-white', bgColor)}>
        <StarIcon className="absolute inset-0 m-1 h-14 w-14 text-white" />
      </div>
    ) : null;

    return (
      <React.Fragment key={`${registration.id}-${registration.startTime}`}>
        {registrationDate > startDate && (
          <li
            className={classNames('group relative mt-px flex', getWeekDayString(day))}
            style={{ gridRow: `${gridRowStartTime} / span ${gridRowDuration}` }}
          >
            <div
              className={classNames(
                'group absolute inset-[4px] flex overflow-y-auto truncate rounded-lg px-[4px] text-[11px]',
                bgColor,
                gridRowDuration <= minGridRowDuration ? 'flex-row gap-[4px]' : 'flex-col'
              )}
            >
              <p className={classNames('font-semibold', registrationTextColor)}>
                {findActivityName(registration.activityId)}
              </p>
            </div>
            <div
              className={classNames(
                'absolute inset-x-[4px] top-[4px] z-40 hidden rounded-lg p-[4px] text-xs group-hover:flex',
                bgColor
              )}
            >
              <div>
                <p
                  className={classNames(timeColor)}
                >{`${registration.startTime} - ${registration.endTime}`}</p>
                <p className={classNames('font-semibold', registrationTextColor)}>
                  {findActivityName(registration.activityId)}
                </p>
                <ul>
                  {registration.symptoms.map((s, i) => (
                    <li key={i} className={classNames(timeColor)}>{`${findSymptomName(s)}`}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="absolute -top-[3Px] -right-[2px] z-50 h-fit w-fit rounded-full bg-white">
              {topRightIcon}
            </div>
          </li>
        )}
      </React.Fragment>
    );
  };

  return (
    <div ref={container} className="isolate flex flex-auto flex-col overflow-auto bg-white">
      <div style={{ width: '165%' }} className="flex max-w-full flex-none flex-col">
        <div
          ref={containerNav}
          className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-[8px]"
        >
          <div className="-mr-px grid grid-cols-7 divide-x divide-[#f3f4f6] border-r border-[#f3f4f6] text-sm leading-[24px] text-[#6b7280]">
            <div className="col-end-1 w-[56px]" />
            {displayWeekDays().map((x) => (
              <div key={x.date} className="z-50 flex items-center justify-center py-[3px]">
                <span>
                  {x.day}{' '}
                  <span className="items-center justify-center font-semibold text-[#111827]">
                    {x.date}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-auto">
          <div className="sticky left-0 z-10 w-[56px] flex-none bg-white ring-1 ring-[#f3f4f6]" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-[#f3f4f6]"
              style={{ gridTemplateRows: 'repeat(48, minmax(35px, 1fr))' }}
            >
              <div ref={containerOffset} className="row-end-1 h-[28px]" />
              <div />
              {timesOfDay.map((time) => (
                <React.Fragment key={time}>
                  <div>
                    <div className="leading-[20px] sticky left-0 z-20 -mt-[40px] -ml-[56px] w-[56px] pr-[8px] text-right text-xs text-[#9ca3af]">
                      {time}
                    </div>
                  </div>
                  <div />
                </React.Fragment>
              ))}
              <div />
            </div>

            {/* Vertical lines */}
            <div className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 grid-rows-1 divide-x divide-[#f3f4f6]">
              <div className="col-start-1 row-span-full" />
              <div className="col-start-2 row-span-full" />
              <div className="col-start-3 row-span-full" />
              <div className="col-start-4 row-span-full" />
              <div className="col-start-5 row-span-full" />
              <div className="col-start-6 row-span-full" />
              <div className="col-start-7 row-span-full" />
              <div className="col-start-8 row-span-full w-[8px]" />
            </div>

            {/* Events */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 pr-[8px]"
              style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
            >
              {data.map((x) => convertRegistrationToCalendarCard(x))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
