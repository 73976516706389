import WeekOverview from "../components/week-overview/WeekOverview";
import { useAuth } from "../contexts/AuthContext";

export default function WeekOverviewPage() {
    const { user } = useAuth();

    if (!user.allowWeekOverview) return <div>Ikke tilladt</div>

    return <WeekOverview />
}
