import React, {useEffect, useState} from 'react';
import icon from "../images/icon.png";
import Button from "../components/Button";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import api from "../utils/api";
import {Link, useParams} from "react-router-dom";

const schema = yup.object().shape({
    password: yup.string().required('Adgangskode skal udfyldes').min(4, 'Adgangskode er for kort - angive minimum 4 karakterer'),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Adgangskode og bekræft adganskode skal være ens'),
});

const defaultValues = {
    requestId: '',
    password: '',
    passwordConfirm: '',
};

const ResetPassword = () => {
    const{ requestId } = useParams();
    const [success, setSuccess] = useState(false);
    const {reset, register, handleSubmit, formState: {isValid, errors}} = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset({...defaultValues, requestId})
    },[requestId, reset])
    const onSubmit = data => {
        api.post(`/auth/createNewPassword`, data)
            .then(() => {
                setSuccess(true);
                reset(defaultValues);
            });
    }

    return (
        <div className="flex h-screen justify-center items-center font-barlow">
            {success ? (
                <div className="flex flex-col w-72">
                    <img src={icon} alt="Logo" className="m-auto pb-3" />
                    <div className="my-3"/>
                        <p className="m-auto">Din nye adgangskode er nu blevet gemt.</p>
                    <div className="my-3"/>
                    <Link replace to="/login" className="m-auto font-medium text-greencategory hover:text-lightgreen">Gå til login</Link>
                </div>
                ) : (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col w-72">
                                <img src={icon} alt="Logo" className="m-auto pb-3" />
                                <div className="my-3"/>
                                <label className="flex flex-col">
                                    <span className="font-semibold text-[14px]">Adgangskode</span>
                                    <input
                                        {...register("password")}
                                        className="mt-1 focus:ring-lightgreen focus:border-lightgreen block w-full shadow-sm sm:text-sm border-gray rounded-md"
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="Indtast din adgangskode"
                                    />
                                    {errors.password && (<p className="mb-1 text-red text-sm">{errors.password.message}</p>)}
                                </label>
                                <div className="my-3"/>
                                <label className="flex flex-col">
                                    <span className="font-semibold text-[14px]">Bekræft adgangskode</span>
                                    <input
                                        {...register("passwordConfirm")}
                                        type="password"
                                        className="mt-1 focus:ring-lightgreen focus:border-lightgreen block w-full shadow-sm sm:text-sm border-gray rounded-md"
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        placeholder="bekræft din adgangskode"
                                    />
                                    {errors.passwordConfirm && (<p className="mb-1 text-red text-sm">{errors.passwordConfirm.message}</p>)}
                                </label>
                                <div className="my-3"/>
                                <Button type="submit" disabled={!isValid}>
                                    <div className="p-3">Nulstil adgangskode</div>
                                </Button>
                            </div>
                        </form>
                    </>
                )}
        </div>
    );
};

export default ResetPassword;
