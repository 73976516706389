import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';

const defaultAuthContext = {
   user: null,
   setUser: () => {},
   isAuthenticated: false,
   isLoading: true,
   loginWithEmailAndPassword: () => {},
   refreshAccessToken: () => {},
   logout: () => {},
};

const AuthContext = createContext(defaultAuthContext);

export const AuthProvider = ({ children }) => {
   const navigate = useNavigate();
   const [user, setUser] = useState(null);
   const [isLoading, setIsLoading] = useState(true);

   let refreshAccessTokenProm = null;

   useEffect(() => {
      async function getUserInfoWithAccessToken() {
         const token = getAccessToken();

         if (token) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await api
               .get('/auth/access-token')
               .then(response => {
                  if (response?.status === 200) {
                     setSession(response.data.access_token);
                     setUser({
                        email: response.data.user.data.email,
                        displayName: response.data.user.data.displayName,
                        mfi: response.data.user.data.mfi,
                        mfs: response.data.user.data.mfs,
                        uid: response.data.user.data.uid,
                        promptNightSleep: response.data.user.data.promptNightSleep,
                        promptNudging: response.data.user.data.promptNudging,
                        daySchema: response.data.user.data.daySchema,
                        weekSchema: response.data.user.data.weekSchema,
                        allowWeekOverview: response.data.user.data.allowWeekOverview,
                     });
                  }
               })
               .catch(error => {});
         }
         setIsLoading(false);
      }

      getUserInfoWithAccessToken();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const loginWithEmailAndPassword = async (email, password) => {
      return await api
         .post('/auth', { email, password, role: 'client' }, { withCredentials: true })
         .then(response => {
            if (response.status === 200) {
               setSession(response.data.access_token);
               setUser({
                  email: response.data.user.data.email,
                  displayName: response.data.user.data.displayName,
                  mfi: response.data.user.data.mfi,
                  mfs: response.data.user.data.mfs,
                  uid: response.data.user.data.uid,
                  promptNightSleep: response.data.user.data.promptNightSleep,
                  promptNudging: response.data.user.data.promptNudging,
                  daySchema: response.data.user.data.daySchema,
                  weekSchema: response.data.user.data.weekSchema,
                  allowWeekOverview: response.data.user.data.allowWeekOverview,
               });
            }
            return response;
         })
         .catch(error => {
            return error.response;
         });
   };

   const getAccessToken = () => {
      return window.localStorage.getItem('access_token');
   };

   const refreshAccessToken = () => {
      if (refreshAccessTokenProm === null) {
         refreshAccessTokenProm = new Promise((resolve, reject) => {
            api.post('/auth/refresh-token')
               .then(response => {
                  refreshAccessTokenProm = null;
                  if (response.data.user) {
                     setSession(response.data.access_token);
                     resolve(response.data.access_token);
                  } else {
                     reject(new Error('Failed to refresh token.'));
                  }
               })
               .catch(error => {
                  refreshAccessTokenProm = null;
                  reject(new Error('Failed to refresh token.'));
               });
         });
      }

      return refreshAccessTokenProm;
   };

   const logout = () => {
      api.post('/auth/signOut');
      window.sessionStorage.removeItem('savedDateData');
      setSession(null);
      setUser(null);
      navigate('/');
   };

   const setSession = access_token => {
      if (access_token) {
         localStorage.setItem('access_token', access_token);
         api.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      } else {
         localStorage.removeItem('access_token');
         delete api.defaults.headers.common.Authorization;
      }
   };

   return (
      <AuthContext.Provider
         value={{
            user,
            setUser,
            isAuthenticated: user != null,
            isLoading,
            loginWithEmailAndPassword,
            refreshAccessToken,
            logout,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export const useAuth = () => useContext(AuthContext);
