export default function RadioSelect(
    {
        title,
        name,
        formValues,
        options,
        description,
        setFormValues,
        errors,
        setErrors
    }
) {

    const onChangeHandler = (name, value) => {
        // Remove error
        if (errors.answers?.length > 0) {
            const updatedAnswerErrors = [...errors.answers]
            const answerErrorIdx = updatedAnswerErrors.indexOf(name)
            if (answerErrorIdx > -1) {
                updatedAnswerErrors.splice(answerErrorIdx, 1);
                setErrors(errors => ({ ...errors, answers: updatedAnswerErrors }))
            }
        }

        // Update answers
        const answers = [...formValues.answers]
        const answer = { questionId: name, value }
        const oldAnswerIndex = answers.map(x => x.questionId).indexOf(name)
        if (oldAnswerIndex > -1)
            answers.splice(oldAnswerIndex, 1); // Removed old
        answers.splice(name, 0, answer) // Inserts new
        answers.sort((a, b) => compareFn(a, b))
        setFormValues(old => ({ ...old, answers }))
    }

    function compareFn(a, b) {
        if (a.questionId < b.questionId)
            return -1;
        if (a.questionId > b.questionId)
            return 1;
        return 0;
    }

    return (
        <div className="pb-16">
            <label className="text-xl font-medium text-slate-900 flex">{title}<span className="text-red pl-3">* </span></label>
            {description && <div>{description}</div>}
            <fieldset className="mt-4">
                <div className="space-y-4">
                    {options.map((option, idx) => (
                        <div key={idx} className="flex items-center">
                            <input
                                id={title + idx}
                                name={title + idx}
                                type="radio"
                                onChange={() => onChangeHandler(name, option.value)}
                                checked={formValues.answers.find(x => x.questionId === name && x.value === option.value) !== undefined ?? false}
                                className={`h-4 w-4 border-slate-300 ${errors.answers?.includes(name) ? "border-red" : ""}`}
                            />
                            <label
                                htmlFor={title + idx}
                                className={`ml-3 block text-sm font-medium ${errors.answers?.includes(name) ? "text-red" : ""}`}>
                                {option.title}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    )
}