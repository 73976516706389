import { useQuery } from "react-query";
import api from "../utils/api";
import { useAuth } from "../contexts/AuthContext";

const getContacts = async (clientUid) =>
  api.get(`clients/${clientUid}/contacts`).then(res => res.data)

export default function useContacts() {
  const { user } = useAuth();

  return useQuery("therapist", () => getContacts(user.uid), { refetchOnReconnect: false, refetchOnWindowFocus: false });
}
