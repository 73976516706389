import { useQuery } from "react-query";
import api from "../utils/api";
import { motion } from "framer-motion";

export default function GoalsPage() {
  const { isLoading, data } = useQuery("clients/goals", () =>
    api.get("clients/goalsmessage").then((response) => response.data), { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  return (
    <motion.div className="flex flex-col gap-8" initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}>
      <div className="flex flex-col p-1 font-barlow text-[22px] text-center">
        <span className="">Mit langsigtede mål er...</span>
        <p className="italic text-[20px]">
          {isLoading ? "Henter..." : data.longTermGoal?.length > 0 ?
            <span className="flex flex-col">
              {data.longTermGoal.split("\r\n").map((x, i) => <span key={i}>{x}</span>)}
            </span>
            : "Intet mål sat endnu."}
        </p>
      </div>
      <div className="flex flex-col p-1 font-barlow text-[22px] text-center">
        <span className="">Mit kortsigtede mål er...</span>
        <p className="italic text-[20px]">
          {isLoading ? "Henter..." : data.shortTermGoal?.length > 0 ?
            <span className="flex flex-col">
              {data.shortTermGoal.split("\r\n").map((x, i) => <span key={i}>{x}</span>)}
            </span>
            : "Intet mål sat endnu."}
        </p>
      </div>
      <div className="flex flex-col p-1 font-barlow text-[22px] text-center">
        <span className="">Aftaler med behandler</span>
        <p className="italic text-[20px]">
          {isLoading ? "Henter..." : data.therapistMessage?.length > 0 ?
            <span className="flex flex-col">
              {data.therapistMessage.split("\r\n").map((x, i) => <span key={i}>{x}</span>)}
            </span>
            : "Ingen besked fra behandler."}
        </p>


      </div>
    </motion.div>
  );
}
