import Header from "./Header";
import Drawer from "./Drawer";

export default function Layout({ children }) {
  return (
    <div className="min-h-screen h-full">
      <div className="flex flex-col">
        <Header />
          <div className="flex flex-col justify-center px-2 m-auto">
            {children}
            <div className="my-3" />
          </div>
      </div>
      <Drawer />
    </div>
  );
}
