import React, {createContext, useContext, useState} from 'react';
import WorkplaceConsentDialog from '../components/WorkplaceConsentDialog';

const AppContext = createContext({
    openDrawer: false,
    setOpenDrawer: () => false,
});

export const AppProvider = ({children}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    
    return (
        <>
        <AppContext.Provider value={{openDrawer, setOpenDrawer}}>
            {children}
        </AppContext.Provider>
        <WorkplaceConsentDialog />
        </>
    );
};

export const useApp = () => useContext(AppContext);

