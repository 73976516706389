import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Button from "./Button";
import { useAuth } from "../contexts/AuthContext";
import { useApp } from "../contexts/AppContext";
import useContacts from "../hooks/useTherapist";
import useClientWorkspaceConsent from "../hooks/useClientWorkspaceConsent";

export default function Drawer() {
    const { openDrawer, setOpenDrawer } = useApp();
    const { logout, user } = useAuth();

    const [showAllContacts, setShowAllContacts] = useState(false)
    const { data: contactData } = useContacts()
    const { data: clientWorkspaceConsent } = useClientWorkspaceConsent();

    const onCloseHandler = () => {
        setOpenDrawer(false)
        setTimeout(() => {
            setShowAllContacts(false)
        }, 300)
    }

    return (
        <Transition.Root show={openDrawer} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden"
                onClose={onCloseHandler}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-300 sm:duration-300"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-300 sm:duration-300"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="pointer-events-auto relative w-screen max-w-md">
                                <div className="flex h-full flex-col bg-white py-6 shadow-xl">
                                    <div className="flex justify-end items-center px-4 sm:px-6">
                                        <button
                                            type="button"
                                            className="rounded-md max-h-[24px] text-gray-300 focus:outline-none focus:ring-2 focus:ring-black"
                                            onClick={() => setOpenDrawer(false)}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <div className="absolute inset-0 px-4 sm:px-6">
                                            <div
                                                className="flex flex-col justify-between h-full font-bold text-center overflow-y-auto"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="flex flex-1 flex-col justify-evenly text-3xl font-barlow">
                                                    <div className="flex flex-col space-y-6 h-full">
                                                        <Link to="/" onClick={() => setOpenDrawer(false)}>
                                                            Aktivitetsregistrering
                                                        </Link>
                                                        <Link
                                                            to="/goals"
                                                            onClick={() => setOpenDrawer(false)}
                                                        >
                                                            Mål og aftaler
                                                        </Link>
                                                        <a href={'https://www.mindagminenergi.dk/aandedraetsoevelser/'}
                                                            target="_blank" rel="noreferrer">
                                                            Åndedrætsøvelser
                                                        </a>
                                                        <Link to={`/mfi?clientUid=${user.uid}`}
                                                            onClick={() => setOpenDrawer(false)}>
                                                            MFI-20
                                                        </Link>
                                                        <Link to={`/mfs?clientUid=${user.uid}`}
                                                            onClick={() => setOpenDrawer(false)}>
                                                            MFS
                                                        </Link>
                                                        <Link to="/reflectionsheet"
                                                            onClick={() => setOpenDrawer(false)}>
                                                            Refleksionsark
                                                        </Link>
                                                        <Link to="/colorscale"
                                                            onClick={() => setOpenDrawer(false)}>
                                                            Farveskala
                                                        </Link>
                                                        <a href={'https://mindagminenergi.dk/wp-content/uploads/2022/07/MDME-Vejledning.pdf'}
                                                            target="_blank" rel="noreferrer">
                                                            MDME-Vejledning
                                                        </a>
                                                        {user.daySchema && (
                                                            <a href={`${process.env.REACT_APP_API_BASE_URI}/files/${user.daySchema}`}
                                                                target="_blank" rel="noreferrer">
                                                                Dagskema
                                                            </a>)
                                                        }
                                                        {user.weekSchema && (
                                                            <a href={`${process.env.REACT_APP_API_BASE_URI}/files/${user.weekSchema}`}
                                                                target="_blank" rel="noreferrer">
                                                                Ugeskema
                                                            </a>)
                                                        }
                                                        {clientWorkspaceConsent?.workplaceConsent &&
                                                            <Link to="/consent"
                                                                onClick={() => setOpenDrawer(false)}>
                                                                Behandling af persondata
                                                            </Link>
                                                        }
                                                        {user.allowWeekOverview &&
                                                            <Link to="/weekoverview"
                                                                onClick={() => setOpenDrawer(false)}>
                                                                Ugeoversigt
                                                            </Link>
                                                        }
                                                        {contactData &&
                                                            <>
                                                                <div className="flex self-center border-t-2 border-black w-1/4" />
                                                                <ul className="flex flex-col text-sm font-normal items-center gap-2">
                                                                    {contactData.slice(0, showAllContacts ? contactData.length : 1).map((contact, idx) =>
                                                                        <li key={idx} className="flex flex-col items-center pt-2 first:pt-0">
                                                                            {idx === 0 && <span>Kontakt:</span>}
                                                                            <span>{`${contact.givenName} ${contact.familyName}`}</span>
                                                                            <span><a href={`mailto: ${contact.email}`}>{contact.email}</a></span>
                                                                            {contact.mobileNumber && <span>Tlf. <a href={`tel:${contact.mobileNumber.replace(" ", "")}`}>{contact.mobileNumber}</a></span>}
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                                {!showAllContacts && contactData.length > 1 &&
                                                                    <button type="button" className="font-bold mt-4 text-sm" onClick={() => setShowAllContacts(true)}>Se alle kontaktpersoner</button>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <Button color="green" onClick={() => logout()}>
                                                    <div className="p-2 font-barlow">Log ud</div>
                                                </Button>
                                            </div>
                                        </div>
                                        {/* /End replace */}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog >
        </Transition.Root >
    );
}
