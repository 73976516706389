import React, { useEffect, useState } from 'react';
import Button from "../components/Button";
import Modal from "../components/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import api from "../utils/api";

const schema = yup.object().shape({
    email: yup.string().email("Skal være en valid email").required("Indtast din email"),
});

const defaultValues = {
    email: "",
};

const ResetPasswordRequest = ({ showModal, setShowModal }) => {
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState('');

    const { reset, register, handleSubmit, formState: { isValid, errors } } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (showModal) {
            setSuccess(false);
            reset(defaultValues);
        }
    }, [reset, showModal])

    const onSubmit = data => {
        api
            .post(`/auth/resetPassword/${data.email}`)
            .then((x) => {
                setSuccess(true);
                reset(defaultValues);
                setMessage(x.data.message);
            })
            .catch(() => {
                setSuccess(false);
            });
    }

    return (
        <Modal open={showModal} setOpen={setShowModal} title="Nulstil adgangskode">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-2">
                    <label className="flex flex-col">
                        <span className="font-semibold text-[14px]">Emailadresse</span>
                        <input
                            {...register("email")}
                            className="mt-1 focus:ring-lightgreen focus:border-lightgreen block w-full shadow-sm sm:text-sm border-gray rounded-md"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            type="email"
                            name="email"
                            placeholder="Indtast din mailadresse"
                        />
                        {errors.email && (<p className="mb-1 text-red text-sm">{errors.email.message}</p>)}
                    </label>
                </div>
                {success &&
                    <div className="mt-2 mb-3">
                        <p>{message}</p>
                    </div>
                }
                <div className='my-2 text-sm'>
                    OBS! Vær opmærksom på, hvis din konto bliver låst, så kan du ikke nulstille din adgangskode. Kontakt din behandler/MDME for yderligere information.
                </div>
                <div className="mt-4 flex justify-between">
                    <Button
                        color="lightgrey"
                        onClick={() => setShowModal(false)}
                    >
                        <div className="py-2">Luk</div>
                    </Button>
                    <Button
                        type="submit"
                        disabled={!isValid}
                    >
                        <div className="py-2">Nulstil</div>
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default ResetPasswordRequest;
