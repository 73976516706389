import { useAuth } from "../contexts/AuthContext";
import { useApp } from "../contexts/AppContext";
import icon from "../images/icon.png";
import { MenuIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function Header() {
  const { user } = useAuth();
  const { setOpenDrawer } = useApp();

  const firstLetterOfName = () => user.displayName.charAt(0);

  return (
    <div className="relative flex flex-col justify-center items-center bg-lightgreen w-full mb-16">
      <div className="flex justify-center items-center pt-4">
        <Link to="/">
          <img src={icon} alt="Logo" className="" width={300} />
        </Link>
      </div>
      <div className="flex flex-col text-center pt-4 pb-16">
        <h1 className="font-barlow font-semibold italic text-[35px]">
          {user.displayName}
        </h1>
        <h1 className="font-barlow italic text-[28px]">{user.email}</h1>
      </div>
      <div className="absolute right-4 bottom-4">
          <button
              type="button"
              onClick={() => setOpenDrawer(true)}
              className="rounded-md max-h-[24px] text-gray-300 focus:outline-none focus:ring-2 focus:ring-black">
              <MenuIcon className="h-6 w-6" />
          </button>
      </div>
      <div className="absolute bottom-[-44px] flex justify-center items-center rounded-full w-[88px] h-[88px] bg-green text-5xl bg-yellow text-darkyellow font-barlow">
        {firstLetterOfName()}
      </div>
    </div>
  );
}
