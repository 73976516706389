import { useQuery } from "react-query";
import api from "../utils/api";
import { useAuth } from "../contexts/AuthContext";

const getSymptoms = async (clientUid) => api.get(`clients/${clientUid}/symptoms`).then((response) => response.data)

export default function useSymptoms() {
  const { user } = useAuth();

  return useQuery("symptoms", () => getSymptoms(user.uid), { refetchOnReconnect: false, refetchOnWindowFocus: false });
}
