import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Input from "../components/Input";
import Spinner from "../components/Spinner";
import { useAuth } from "../contexts/AuthContext";
import icon from "../images/icon.png";
import ResetPasswordRequest from "./ResetPasswordRequest";
import Modal from "../components/Modal";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [attemptingSignIn, setAttemptingSignIn] = useState(false);
  const [errors, setErrors] = useState({});
  const { loginWithEmailAndPassword } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [failedAttempts, setFailedAttempts] = useState(0);

  function emptyInput() {
    return email.length < 1 || password.length < 1;
  }

  function onEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      signIn()
    }
  }

  function signIn() {
    setAttemptingSignIn(true);
    setErrors({});
    loginWithEmailAndPassword(email, password)
      .then((response) => {
        setAttemptingSignIn(false);
        if (response.status === 200) {
          navigate("/", { replace: true });
        } else {
          const failedAttempts = Number(response.data.errors?.failedAttempts);
          if (failedAttempts > 1 && response.data.errors?.message) {
            setWarningText(response.data.errors?.message);
            setFailedAttempts(failedAttempts);
            setShowWarningDialog(true);
          }
          if (failedAttempts < 3)
            setErrors({
              email: response.data.errors.email,
              password: response.data.errors.password,
            });
        }
      })
      .catch((error) => {
        setAttemptingSignIn(false);
      });
  }

  return (
    <div className="flex h-screen justify-center items-center font-barlow">
      <div className="flex flex-col w-72">
        <img src={icon} alt="Logo" className="m-auto pb-3" />
        <div className="my-3" />
        <label className="flex flex-col">
          <span className="font-semibold text-[14px]">Emailadresse</span>
          <Input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={onEnter}
            placeholder="Indtast din mailadresse"
          />
          {errors.email ? (
            <p className="mb-1 text-red text-sm">{errors.email}</p>
          ) : (
            <div className="my-3" />
          )}
        </label>

        <label className="flex flex-col">
          <span className="font-semibold text-[14px]">Adgangskode</span>
          <Input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={onEnter}
            placeholder="Indtast din adgangskode"
          />
          {errors.password ? (
            <p className="mb-1 text-red text-sm">{errors.password}</p>
          ) : (
            <div className="my-3" />
          )}
        </label>
        <Button onClick={signIn} disabled={attemptingSignIn || emptyInput()}>
          {attemptingSignIn ? (
            <div className="inline-flex items-center p-3">
              <Spinner />
            </div>
          ) : (
            <div className="p-3">Log ind</div>
          )}
        </Button>

        <p className={"text-sm underline cursor-pointer text-center"} onClick={() => setShowModal(true)}>
          Jeg har glemt min adgangskode
        </p>

      </div>
      <ResetPasswordRequest showModal={showModal} setShowModal={setShowModal} />
      <WarningDialog showWarningDialog={showWarningDialog} setShowWarningDialog={setShowWarningDialog} text={warningText} failedAttempts={failedAttempts} />
    </div>
  );
}

function WarningDialog({ showWarningDialog, setShowWarningDialog, text, failedAttempts }) {
  const textLines = typeof text === 'object' ? text[0].split('\n') : [text];

  return <Modal open={showWarningDialog} setOpen={setShowWarningDialog} title={failedAttempts > 2 ? "Låst konto" : "Forkert adgangskode"}>
    <div>
      {textLines.map((line, idx) => (
        <div key={idx} className="mt-2">{line}</div>
      ))}
      <div className="mt-4 flex justify-center">
        <Button
          color="lightgrey"
          onClick={() => setShowWarningDialog(false)}
        >
          <div className="py-2">Forstået</div>
        </Button>
      </div>
    </div>
  </Modal>
}