import useSymptoms from "../hooks/useSymptoms";

export default function ColorScalePage() {
    const { data, isLoading, isError } = useSymptoms()

    const greenSymptoms = data?.filter((x) => x.enabled && x.category === 1);
    const yellowSymptoms = data?.filter((x) => x.enabled && x.category === 2);
    const redSymptoms = data?.filter((x) => x.enabled && x.category === 3);

    if (isError) return <div className="flex items-center justify-center h-screen">Der skete en fejl.</div>
    if (isLoading) return <div className="flex items-center justify-center h-screen">Henter..</div>

    return (
        <div className="flex justify-center">
            <div className="max-w-[1000px] flex-col p-12 flex text-[13px]">
                <h1 className="text-lg font-bold uppercase text-[#4FA377] underline underline-offset-4">
                    Forklaring på farveskala
                </h1>
                <p className="my-6">
                    Her er skrevet nogle symptomer og udsagn, der beskriver, hvordan du kan have det i
                    forbindelse med dine daglige aktiviteter, samt beskriver din grad af hjernetræthed.
                </p>

                <div className="flex flex-row min-h-[220px]">
                    <div className="flex flex-1 flex-col justify-between bg-[#FFF6E9] p-2 gap-8">
                        <ul className="list-inside list-disc">
                            {greenSymptoms.map((x) => (
                                <li key={x.id}>{x.name}</li>
                            ))}
                        </ul>
                        <span>Jeg har lyst til at lave noget og være i aktivitet</span>
                    </div>
                    <div className="flex w-8 items-center justify-center bg-greencategory">
                        <div className="rotate-90 whitespace-nowrap uppercase text-white">
                            INGEN HJERNETRÆTHED
                        </div>
                    </div>
                </div>

                <div className="my-4" />

                <div className="flex flex-row min-h-[220px]">
                    <div className="flex flex-1 flex-col justify-between bg-[#FFF6E9] p-2 gap-8">
                        <ul className="list-inside list-disc">
                            {yellowSymptoms.map((x) => (
                                <li key={x.id}>{x.name}</li>
                            ))}
                        </ul>
                        <div className="flex flex-col gap-1">
                            <span>Jeg har ikke lyst til at lave noget eller til at være i aktivitet</span>
                            <span>
                                <span className="font-bold">OBS! </span>
                                Har du symptomer på let/moderat hjernetræthed bør du tage en hjernepause
                            </span>
                        </div>
                    </div>
                    <div className="flex w-8 items-center justify-center bg-yellowcategory">
                        <div className="rotate-90 whitespace-nowrap uppercase text-white">
                            LET-MODERAT HJERNETRÆTHED
                        </div>
                    </div>
                </div>

                <div className="my-4" />

                <div className="flex flex-row min-h-[220px]">
                    <div className="flex flex-1 flex-col justify-between bg-[#FFF6E9] p-2 gap-8">
                        <ul className="list-inside list-disc">
                            {redSymptoms.map((x) => (
                                <li key={x.id}>{x.name}</li>
                            ))}
                        </ul>
                        <span>
                            Jeg gruer for at skulle lave noget eller jeg skal tage mig meget sammen for at udføre en
                            aktivitet
                        </span>
                    </div>
                    <div className="flex w-8 items-center justify-center bg-redcategory">
                        <div className="rotate-90 whitespace-nowrap uppercase text-white">
                            MARKANT HJERNETRÆTHED
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
